import React from "react";
import { Field, ErrorMessage } from "formik";

const ColorFormikField = ({ fieldName, values, label }) => {
  return (
    <div
      className="d-flex flex-column align-items-start justify-content-center "
      style={{ width: "300px" }}
    >
      <label htmlFor={fieldName} style={{ color: "red" }} className="mt-2">
        {label}
      </label>
      <div>
        <div className="d-flex align-items-center">
          <Field name={fieldName} className="form-control" type="text" />
          <div
            style={{
              width: "50px",
              height: "20px",
              backgroundColor: values[fieldName],
              marginLeft: "10px",
            }}
          />
        </div>
        <ErrorMessage
          name={fieldName}
          component="div"
          className="alert alert-danger"
        />
      </div>
    </div>
  );
};

export default ColorFormikField;
