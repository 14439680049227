import React from "react";
import styled from "@emotion/styled";

const Mask = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 90px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

const DarkMask = () => {
  return <Mask></Mask>;
};

export default DarkMask;
