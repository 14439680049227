import React from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

// Define the spin animation
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

// Spinner container for centering
const SpinnerContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8); /* Optional overlay */
  z-index: 9999;
`;

// Spinner styles
const SpinnerElement = styled.div`
  width: 50px;
  height: 50px;
  border: 5px solid transparent;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;

const LoadingSpinner = () => {
  return (
    <SpinnerContainer>
      <SpinnerElement />
    </SpinnerContainer>
  );
};

export default LoadingSpinner;
