import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Axios } from "../../../config";

export const useEditSubcategoryMutation = (id) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: async (updatedSubcategory) =>
      await Axios.patch(`subcategories/${id}`, updatedSubcategory).then(
        (res) => {
          console.log(res.data);
        }
      ),
    onMutate: (updatedSubcategory) => {
      const previousSubcategories = queryClient.getQueryData([
        "getListOfSubcategories",
      ]);
      queryClient.setQueryData(["getListOfSubcategories"], (oldData = []) => {
        return oldData.map((subcategory) =>
          subcategory.id === id
            ? { ...updatedSubcategory, id: id }
            : subcategory
        );
      });
      return { previousSubcategories };
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["getListOfSubcategories"]);
      navigate("/admin/lista-subcategorii");
    },
    onError: (error, context) => {
      queryClient.setQueryData(
        ["getListOfSubcategories"],
        context.previousSubcategories
      );
      console.error("Eroare la editarea subcategoriei:", error);
    },
  });
};
