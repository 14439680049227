import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import SearchBar from "../../Components/SearchBar";
import { useIngredientsData } from "../../../Contexts/IngredientsDataContext";
import { useDeleteIngredient } from "../../../myHooks/myMutations/deleteMutations/deleteIngredientMutation";

const ContainerWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
const Content = styled.div`
  width: 80%;
`;
const BtnsDiv = styled.div`
  //border: 2px solid red;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
const Btn = styled.button``;
const IngredientsContent = styled.div`
  width: 100%;
`;
const IngredientName = styled.div``;
const IngredientWrap = styled.div`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  padding: 20px;
  width: 100%;
  margin-bottom: 10px;
`;
const IngredientDescription = styled.div`
  word-wrap: break-word; // Allows long words to break and wrap to the next line
  white-space: normal; // Ensures text can wrap onto the next line
`;

const ListOfIngredients = () => {
  const [filteredIngredients, setFilteredIngredients] = useState([]);
  const { data: ingredientsData } = useIngredientsData();

  useEffect(() => {
    if (ingredientsData) {
      setFilteredIngredients(ingredientsData);
    }
  }, [ingredientsData]);

  const deleteIngredient = useDeleteIngredient();

  return (
    <>
      {ingredientsData && (
        <ContainerWrap>
          <h5>Lista ingrediente</h5>
          <Link to="/admin/adauga-ingredient">Adauga ingredient</Link>
          <Content>
            <SearchBar
              onResults={setFilteredIngredients}
              list={ingredientsData}
            />
            <IngredientsContent>
              {filteredIngredients.map((item) => {
                return (
                  <IngredientWrap key={item.id}>
                    <BtnsDiv>
                      <Btn
                        className="btn "
                        style={{ backgroundColor: " rgba(255,215,0,1)" }}
                      >
                        <Link
                          to={`/admin/editeaza-ingredient/${item.id}`}
                          style={{
                            textDecoration: "none",
                            color: "black",
                          }}
                        >
                          Editează
                        </Link>
                      </Btn>
                      <Btn
                        className="btn "
                        style={{
                          backgroundColor: "rgba(184,15,10,1)",
                          color: "white",
                        }}
                        onClick={() => deleteIngredient(item.id)}
                      >
                        Sterge
                      </Btn>
                    </BtnsDiv>
                    <div>
                      {" "}
                      <IngredientName>
                        <span className="text-danger">Nume:</span> {item.name}
                      </IngredientName>
                      <IngredientDescription>
                        <div className="text-danger">Descriere : </div>
                        <div>{item.description}</div>
                      </IngredientDescription>
                    </div>
                  </IngredientWrap>
                );
              })}
            </IngredientsContent>
          </Content>
        </ContainerWrap>
      )}
    </>
  );
};

export default ListOfIngredients;
