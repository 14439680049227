import React, { useState } from "react";
import styled from "@emotion/styled";
import { Formik, Form } from "formik";
import { allFieldNames, themeSections } from "../../../Data/themeVariables";
import ColorsSection from "../../Components/ThemeComponents/ColorsSection";
import SelectThemeImagesSection from "../../Components/ThemeComponents/SelectThemeImagesSection";
import ThemeNameInput from "../../Components/ThemeComponents/ThemeNameInput";
import { useAddTheme } from "../../../myHooks/myMutations/addMutations/addThemeMutation";

const Section = styled.div`
  width: 90%;
  border: 2px dotted blue;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const AddTheme = () => {
  const [showThemeImages, setShowThemeImages] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [isActiveBtn, setIsActiveBtn] = useState("");
  const mutation = useAddTheme();

  const initialValues = allFieldNames.reduce((acc, field) => {
    acc[field] = "";
    return acc;
  }, {});
  initialValues.isActive = false;

  const handleImageSelect = (imageFieldName, image, setFieldValue) => {
    if (image.src) {
      setShowThemeImages("");
      setFieldValue(imageFieldName, image.src);
      selectedImages.push({
        id: image.id,
        src: image.src,
        fieldName: imageFieldName,
      });
    }
  };

  const onSubmit = (data, resetForm) => {
    console.log("data:", data);
    mutation.mutate(data);
  };
  return (
    <div className="d-flex flex-column align-items-center justify-content-center w-100">
      <h3 className="mb-4 mt-4 ">Adauga tema (va fi setata ca inactiva): </h3>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => onSubmit(values, resetForm)}
      >
        {({ setFieldValue, values, resetForm }) => {
          return (
            <Form
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* ------------------------NUME TEMA-------------------- */}
              <ThemeNameInput />

              {themeSections.map((section) => {
                return (
                  <Section key={section.id}>
                    <h5 style={{ color: "blue" }} className="mt-4">
                      {section.name}
                    </h5>
                    {/* ------------------------SECTIUNEA CULORI-------------------- */}
                    <ColorsSection section={section} values={values} />
                    {/* ------------------------SECTIUNEA IMAGINI-------------------- */}
                    <SelectThemeImagesSection
                      section={section}
                      setShowThemeImages={setShowThemeImages}
                      isActiveBtn={isActiveBtn}
                      setIsActiveBtn={setIsActiveBtn}
                      showThemeImages={showThemeImages}
                      handleImageSelect={handleImageSelect}
                      setFieldValue={setFieldValue}
                      selectedImages={selectedImages}
                      page="AddTheme"
                    />
                  </Section>
                );
              })}
              <button
                type="submit"
                className="btn btn-primary mt-2 mb-5"
                style={{ height: "70px", width: "50%" }}
              >
                <div style={{ fontSize: "25px" }}> Adauga tema</div>
              </button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddTheme;
