export const addProductInitialValues = (visibility) => {
  const initialValues = {
    selectedMenuItemsCategoriesSubcategories: [],
    name: "",
    image: null,
    recipe: "",
    valability: "",
    unitOfMeasure: "",
    visibility: visibility,
    useInstructions: [""],
    specifications: [{ title: "", specification: "" }],
    warnings: [],
    descriptions: [""],
    pricesByQnt: [{ price: "", quantity: "" }],
    selectedIngredientsIds: [],
  };

  return initialValues;
};
