import React from "react";

const Warnings = ({ completeProduct }) => {
  return (
    <table className="mt-2 table">
      <thead>
        <tr>
          <th scope="col" className="text-danger">
            Warnings:
          </th>
        </tr>
      </thead>
      <tbody>
        {completeProduct.warnings &&
          completeProduct.warnings.map((warning) => {
            return (
              <tr key={warning.id}>
                <td>
                  <div>{warning.warning}</div>
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default Warnings;
