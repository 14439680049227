import { Routes, Route } from "react-router-dom";
import { MenuItemsDataProvider } from "./Contexts/MenuItemsDataContext";
import { CategoriesDataProvider } from "./Contexts/CategoriesDataContext";

import LoadingErrorProvider from "./Contexts/LoadingErrorProvider";
import ClientViews from "./Client/ClientViews";
import AdminViews from "./Admin/AdminViews";
import "./App.css";
import React, { useEffect, useState } from "react";
import { useThemesData } from "./Contexts/ThemesDataContext";
import { ThemeImagesDataProvider } from "./Contexts/ThemeImagesDataContext";

function App() {
  const { data: themesData } = useThemesData();
  const [currentTheme, setCurrentTheme] = useState(null);
  useEffect(() => {
    if (themesData) {
      const currTheme = themesData.find((theme) => theme.isActive == 1);
      setCurrentTheme(currTheme);
    }
  }, [themesData]);
  //console.log("currentTheme:", currentTheme);
  return (
    <ThemeImagesDataProvider>
      <MenuItemsDataProvider>
        <CategoriesDataProvider>
          <LoadingErrorProvider>
            <Routes>
              <Route
                path="/*"
                element={<ClientViews currentTheme={currentTheme} />}
              />
              <Route path="/admin/*" element={<AdminViews />} />
            </Routes>
          </LoadingErrorProvider>
        </CategoriesDataProvider>
      </MenuItemsDataProvider>
    </ThemeImagesDataProvider>
  );
}

export default App;
