import React from "react";
import { Formik, Form } from "formik";
import { useAddMenuItemMutation } from "../../../myHooks/myMutations/addMutations/addMenuItemMutation";
import { addMenuItemInitialValues } from "../../initialValuesFormik/menuItem/addMenuItemInitialValues";
import FormikField from "../../Components/FormikField";
import { addMenuItemValidationSchema } from "../../validationSchemas/menuItem/addMenuItemValidationSchema";
const AddMenuItem = () => {
  const mutation = useAddMenuItemMutation();
  const onSubmit = (data) => {
    mutation.mutate(data);
  };
  return (
    <div className="d-flex flex-column align-items-center justify-content-center w-100">
      <div
        className="d-flex flex-column align-items-center justify-content-center "
        style={{ width: "50%" }}
      >
        <h5>Adauga menu item</h5>
        <Formik
          initialValues={addMenuItemInitialValues}
          validationSchema={addMenuItemValidationSchema}
          onSubmit={onSubmit}
        >
          <Form style={{ width: "100%" }}>
            <FormikField name="name" labelText="Nume" fieldType="text" />
            <FormikField
              name="hasdropdown"
              labelText="Hasdropdown"
              fieldType="text"
            />
            <FormikField name="path" labelText="Path" fieldType="text" />
            <button type="submit" className="btn btn-primary mt-2">
              Adauga
            </button>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default AddMenuItem;
