import React from "react";
import styled from "@emotion/styled";

const RecipeWrap = styled.div`
  margin-top: 20px;
`;

const RecipeDiv = ({ product, currentTheme }) => {
  return (
    <RecipeWrap>
      <span
        style={{
          fontWeight: "600",
          color: currentTheme
            ? currentTheme.productPageValabilityColor
            : "rgb(210, 4, 45)",
        }}
      >
        INCI:{" "}
      </span>
      {product.recipe}
    </RecipeWrap>
  );
};

export default RecipeDiv;
