import React from "react";
import styled from "@emotion/styled";
import ProductImage from "../SingleCard/ProductImage";
import CardTitle from "../SingleCard/CardTitle";
import { Card } from "react-bootstrap";
import CardSpecifications from "../SingleCard/CardSpecifications";
import SelectPriceDiv from "../SingleCard/SelectPriceDiv";
import DetailsBtn from "../SingleCard/DetailsBtn";

const StyledBootstrapCard = styled(Card)`
  width: 270px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px;

  &:hover {
    transform: scale(1.02);
    color: red;
  }
`;
const StyledCardBody = styled(Card.Body)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const ProductCard = styled.div`
  margin-bottom: 20px;
`;
const SingleCard = ({ currentProduct, currentTheme }) => {
  return (
    <ProductCard>
      {currentProduct && (
        <StyledBootstrapCard
          style={{
            backgroundColor: currentTheme
              ? currentTheme.cardBackgroundColor
              : "white",
          }}
        >
          <ProductImage currentProduct={currentProduct} />
          <StyledCardBody>
            <CardTitle
              currentTheme={currentTheme}
              currentProduct={currentProduct}
            />
            <CardSpecifications
              currentTheme={currentTheme}
              currentProduct={currentProduct}
            />
            <SelectPriceDiv
              currentTheme={currentTheme}
              currentProduct={currentProduct}
            />
            <DetailsBtn
              currentTheme={currentTheme}
              currentProduct={currentProduct}
            />
          </StyledCardBody>
        </StyledBootstrapCard>
      )}
    </ProductCard>
  );
};

export default SingleCard;
