import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Axios } from "../../../config";

export const useEditIngredientMutation = (id) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: async (data) =>
      await Axios.patch(`ingredients/${id}`, data).then((res) => {
        console.log("Raspuns de la backend dupa editare:", res.data);
      }),
    onMutate: async (newIngredient) => {
      const previousIngredients = queryClient.getQueryData([
        "getListOfIngredients",
      ]);
      queryClient.setQueryData(["getListOfIngredients"], (oldData = []) => {
        return oldData.map((item) =>
          item.id === newIngredient.id ? { ...newIngredient, id: id } : item
        );
      });
      return { previousIngredients };
    },
    onSuccess: () => {
      navigate("/admin/lista-ingrediente");
      queryClient.invalidateQueries(["getListOfIngredients"]);
    },
    onError: (error) => {
      console.error("Eroare la editarea ingredientului:", error);
    },
  });
};
