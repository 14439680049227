import React from "react";
import styled from "@emotion/styled";
import { Field, ErrorMessage } from "formik";

const ThemeNameWrap = styled.div`
  padding: 10px;
`;
const ThemeNameInput = () => {
  return (
    <ThemeNameWrap>
      <label htmlFor="themeName" style={{ color: "red" }}>
        Nume tema
      </label>
      <Field name="themeName" className="form-control" type="text" />
      <ErrorMessage
        name="themeName"
        component="div"
        className="alert alert-danger"
      />
    </ThemeNameWrap>
  );
};

export default ThemeNameInput;
