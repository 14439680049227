import { useQuery } from "@tanstack/react-query";
import { Axios } from "../../config";

const useGetSubcategoriesData = () => {
  const getListOfSubcategories = async () => {
    const { data } = await Axios.get("subcategories");
    return data;
  };

  return useQuery({
    queryKey: ["getListOfSubcategories"],
    queryFn: getListOfSubcategories,
  });
};

export default useGetSubcategoriesData;
