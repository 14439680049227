import React from "react";
import styled from "@emotion/styled";
import FormikField from "../FormikField";
import { FieldArray } from "formik";

const Label = styled.div`
  color: red;
`;
const RemoveBtn = styled.button`
  width: 80px;
  height: 40px;
`;
const ListDiv = styled.div`
  flex-grow: 1;
`;
const CustomList = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;
const WarningsDiv = ({ name, values }) => {
  return (
    <div>
      <Label htmlFor={name}>Atentionari:</Label>
      <FieldArray name={name}>
        {({ push, remove }) => (
          <div className="mb-2">
            {values.warnings.map((_, index) => (
              <CustomList key={index}>
                <ListDiv>
                  <FormikField
                    name={`warnings[${index}]`}
                    placeholder="Atentionare"
                    fieldType="textarea"
                  />
                </ListDiv>

                <RemoveBtn
                  type="button"
                  className="btn btn-danger"
                  onClick={() => remove(index)}
                >
                  Remove
                </RemoveBtn>
              </CustomList>
            ))}
            <button
              type="button"
              className="btn btn-info"
              onClick={() => push("")}
            >
              Add Warning
            </button>
          </div>
        )}
      </FieldArray>
    </div>
  );
};

export default WarningsDiv;
