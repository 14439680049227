import * as Yup from "yup";

export const addCategoryValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Numele este obligatoriu")
    .min(3, "Numele trebuie să aibă cel puțin 3 caractere"),

  path: Yup.string().required("Path-ul este obligatoriu"),

  selectedMenuItemId: Yup.string()
    .nullable()
    .required("Trebuie să selectezi un element din meniu"),
  selectedMenuItemOption: Yup.object()
    .nullable()
    .required("Trebuie să selectezi o opțiune validă din listă")
    .shape({
      value: Yup.string().required(),
      label: Yup.string().required(),
    }),
});
