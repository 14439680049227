import React, { createContext, useContext } from "react";
import useGetProductContainerImagesData from "../myHooks/productContainerImage/useGetProductContainerImagesData";

// Creează contextul
const ProductContainerImagesDataContext = createContext();

// Creează un custom hook pentru a folosi contextul
export const useProductContainerImagesData = () => {
  return useContext(ProductContainerImagesDataContext);
};

// Creează providerul pentru context
export const ProductContainerImagesDataProvider = ({ children }) => {
  const {
    data: productContainerImagesData,
    isLoading: isLoadingProductContainerImagesData,
    isError: isErrorProductContainerImagesData,
    error: errorProductContainerImagesData,
  } = useGetProductContainerImagesData();

  return (
    <ProductContainerImagesDataContext.Provider
      value={{
        data: productContainerImagesData,
        isLoading: isLoadingProductContainerImagesData,
        isError: isErrorProductContainerImagesData,
        error: errorProductContainerImagesData,
      }}
    >
      {children}
    </ProductContainerImagesDataContext.Provider>
  );
};
