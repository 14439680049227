import React, { useState } from "react";
import styled from "@emotion/styled";

const MyButton = styled.button`
  background-color: inherit;
  border: none;
  padding: 12px 16px;
  margin: 0;
  color: inherit;
  font: inherit;
  cursor: pointer;
  font-weight: 500;
`;

const MenuItemBtn = ({ currentTheme, menuItem, handleDropdownToggle }) => {
  return (
    <MyButton
      className=" d-flex align-items-center"
      onClick={() => handleDropdownToggle(menuItem.name)}
    >
      <div
        style={{
          color: currentTheme ? `${currentTheme.headerTextColor}` : "black",
        }}
      >
        {menuItem.name}
      </div>

      <i
        className="fa fa-caret-down ms-1 "
        style={{
          color: currentTheme ? `${currentTheme.headerTextColor}` : "black",
        }}
      ></i>
    </MyButton>
  );
};

export default MenuItemBtn;
