import { useQuery } from "@tanstack/react-query";
import { Axios } from "../../config";

const useGetThemesData = () => {
  const getListOfThemes = async () => {
    const { data } = await Axios.get("themes");
    return data;
  };

  return useQuery({
    queryKey: ["getListOfThemes"],
    queryFn: getListOfThemes,
  });
};

export default useGetThemesData;
