import React from 'react'
import styled from "@emotion/styled";

const Specifications = styled.ul`
  width: 100%;
  font-size: 16px;
  line-height: 20px;
`;
const SingleSpecification = styled.li`
  padding: 3px 0;
`;

const CardSpecifications = ({currentProduct,currentTheme}) => {
  return (
    <Specifications>
    {currentProduct.specifications.map((item) => {
      return (
        <SingleSpecification key={item.id}>
          <span
            style={{
              color: currentTheme
                ? ` ${currentTheme.cardPriceColor}`
                : "rgba(143, 30, 53, 255)",
            }}
          >
            {item.title}:{" "}
          </span>
          <span
            style={{
              color: currentTheme
                ? ` ${currentTheme.cardSpecificationsColor}`
                : "rgba(143, 30, 53, 255)",
            }}
          >
            {item.specification}
          </span>
        </SingleSpecification>
      );
    })}
  </Specifications>
  )
}

export default CardSpecifications