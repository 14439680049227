import React from "react";
import styled from "@emotion/styled";
import ColorFormikField from "./ColorFormikField";

const MyColorsSection = styled.div`
  //border: 2px solid red;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px 0 10px 0;
`;

const ColorsSection = ({ section, values }) => {
  return (
    <MyColorsSection>
      {section.colorFields.map((colorField) => {
        return (
          <ColorFormikField
            key={colorField.id}
            fieldName={colorField.colorFieldName}
            values={values}
            label={colorField.label}
          />
        );
      })}
    </MyColorsSection>
  );
};

export default ColorsSection;
