import React from "react";
import styled from "@emotion/styled";

const CineSuntEu = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.4);
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CineSuntEuContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  @media screen and (max-width: 600px) {
    width: 90%;
  }
  @media screen and (min-width: 600px) {
    width: 60%;
  }
`;
const TitleImgDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;
const MyDescriptionText = styled.div``;
const MyDescriptionFragment = styled.div`
  padding: 3px;
`;

const Title = styled.div`
  font-size: 25px;
  font-weight: 500;
  text-align: center;
`;
const CineSuntEuSection = ({ currentTheme }) => {
  return (
    <CineSuntEu
      style={{
        backgroundColor: currentTheme
          ? currentTheme.homePageCineSuntEuBackgroundColor
          : " white",
      }}
    >
      <CineSuntEuContent>
        <TitleImgDiv>
          <img
            src={currentTheme ? currentTheme.homePageCineSuntEuImgSrc : null}
            alt="cine sunt eu img"
            style={{ width: "200px" }}
          />
          <Title>Cine sunt eu?</Title>
        </TitleImgDiv>
        <MyDescriptionText>
          <MyDescriptionFragment>
            <span style={{ opacity: "0" }}>Ba</span>O femeie care iubește să
            învețe de toate ! Deși formarea mea este una reală ( facultatea de
            Matematică la Univ. București), am avut o atracție mare către partea
            de medicină , astfel că am urmat în mod particular cursurile acestei
            facultăți, pentru ca mai apoi să intru în domeniul produselor cât
            mai naturale, indiferent că vorbim de alimentație sau de cosmetice.
          </MyDescriptionFragment>
          <MyDescriptionFragment>
            Am experimentat diferite stiluri de alimentație (vegetarian, vegan,
            keto și chiar carnivor), pentru că eu dacă nu încerc, nu-mi dau cu
            părerea. După mai bine de 12 ani de studiu și experimentare în
            medicină,diete,chimie cosmetică și alte vrute și nevrute, m-am
            întors la originile mele reale și am descoperit o altă pasiune,
            programarea.
          </MyDescriptionFragment>
          <MyDescriptionFragment>
            Dar m-a lovit de numa'! Așa că am combinat programarea cu produsele
            cosmetice și a ieșit acest site, pe care l-am prezentat la lucrarea
            de dizertație când am terminat cursul postuniversitar de la
            Politehnică. Da, știu, mă dau mare, dar sunt foarte mândră de
            realizare, mi-am pus sufletul nu numai în produsele pe care le fac,
            dar și în acest site.
          </MyDescriptionFragment>
          <MyDescriptionFragment>
            Nu vă mai plictisesc cu pasiunile mele și lipsa de modestie și vă
            las cu o urare de bine și sănătate!
          </MyDescriptionFragment>
        </MyDescriptionText>
      </CineSuntEuContent>
    </CineSuntEu>
  );
};

export default CineSuntEuSection;
