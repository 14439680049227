import React, { useState } from "react";
import styled from "@emotion/styled";
import { useMenuItemsData } from "../../../../../Contexts/MenuItemsDataContext";
import { useCategoriesData } from "../../../../../Contexts/CategoriesDataContext";
import NoDropdownMenuItem from "./NoDropdownMenuItem";
import DropdownMenuItem from "./DropdownMenuItem";

const Menu = styled.div`
  padding: 2px;
  width: 100%;
`;

const SideMenu = ({ currentTheme, sideNav, setSideNav }) => {
  const { data: menuItemsData } = useMenuItemsData();
  const { data: categoriesData } = useCategoriesData();

  const [openDropdown, setOpenDropdown] = useState(null);
  const handleDropdownToggle = (menuItemName) => {
    setOpenDropdown((prev) => (prev === menuItemName ? null : menuItemName));
  };

  return (
    <>
      {menuItemsData && categoriesData && (
        <Menu>
          {menuItemsData.map((menuItem) => {
            if (menuItem.hasdropdown === "no") {
              return (
                <NoDropdownMenuItem
                  menuItem={menuItem}
                  currentTheme={currentTheme}
                  setSideNav={setSideNav}
                  sideNav={sideNav}
                />
              );
            } else {
              return (
                <DropdownMenuItem
                  menuItem={menuItem}
                  currentTheme={currentTheme}
                  setSideNav={setSideNav}
                  sideNav={sideNav}
                  categoriesData={categoriesData}
                  handleDropdownToggle={handleDropdownToggle}
                  openDropdown={openDropdown}
                />
              );
            }
          })}
        </Menu>
      )}
    </>
  );
};

export default SideMenu;
