import React from "react";
import { useMenuItemsData } from "./MenuItemsDataContext";
import { useCategoriesData } from "./CategoriesDataContext";
import { useThemeImagesData } from "./ThemeImagesDataContext";
import { useThemesData } from "./ThemesDataContext";
import { useIngredientsData } from "./IngredientsDataContext";
import { useSubcategoriesData } from "./SubcategoriesDataContext";
import LoadingSpinner from "../Client/Components/LoadingSpinner";

const LoadingErrorProvider = ({ children }) => {
  const ingredients = useIngredientsData();
  const menuItems = useMenuItemsData();
  const categories = useCategoriesData();
  const themeImages = useThemeImagesData();
  const themes = useThemesData();
  const subcategories = useSubcategoriesData();

  const isLoading = [
    ingredients,
    menuItems,
    categories,
    themeImages,
    themes,
    subcategories,
  ].some((data) => data?.isLoading);

  const errors = [
    ingredients,
    menuItems,
    categories,
    themeImages,
    themes,
    subcategories,
  ]
    .filter((data) => data?.isError)
    .map((data) => data?.error?.message);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (errors.length > 0) {
    return (
      <div className="error-container">
        <h1>Errors occurred:</h1>
        <ul>
          {errors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      </div>
    );
  }

  return children;
};

export default LoadingErrorProvider;
