import { useQueryClient } from "@tanstack/react-query";
import { Axios } from "../../../config";

export const useDeleteIngredient = () => {
  const queryClient = useQueryClient();

  const deleteIngredient = async (id) => {
    await queryClient.cancelQueries(["getListOfIngredients"]);

    const previousIngredients = queryClient.getQueryData([
      "getListOfIngredients",
    ]);

    queryClient.setQueryData(["getListOfIngredients"], (oldData) =>
      oldData.filter((ingredient) => ingredient.id !== id)
    );

    try {
      await Axios.delete(`ingredients/${id}`);
    } catch (error) {
      queryClient.setQueryData(["getListOfIngredients"], previousIngredients);
      console.error("Eroare la ștergerea ingredientului:", error);
    }
  };
  return deleteIngredient;
};
