import React from "react";

const LeftImage = ({ currentTheme }) => {
  return (
    <>
      {currentTheme && currentTheme.mobileHeaderLeftImgSrc && (
        <img
          style={{ height: "100%" }}
          src={currentTheme.mobileHeaderLeftImgSrc}
          alt="left img"
        />
      )}
    </>
  );
};

export default LeftImage;
