import { useQueryClient } from "@tanstack/react-query";
import { Axios } from "../../../config";

export const useDeleteMenuItem = () => {
  const queryClient = useQueryClient();
  const deleteMenuitem = async (id) => {
    await queryClient.cancelQueries(["getListOfMenuItems"]);
    const previousMenuItems = queryClient.getQueryData(["getListOfMenuItems"]);
    queryClient.setQueryData(["getListOfMenuItems"], (oldData) =>
      oldData.filter((menuItem) => menuItem.id !== id)
    );

    try {
      await Axios.delete(`menuitems/${id}`);
    } catch (error) {
      queryClient.setQueryData(["getListOfMenuItems"], previousMenuItems);
      console.error("Eroare la ștergerea menu item:", error);
    }
  };
  return deleteMenuitem;
};
