import React, { useState } from "react";
import styled from "@emotion/styled";
import MenuItemBtn from "./MenuItemBtn";
import DropdownDiv from "./DropdownDiv";

const DropdownMenuItemWrap = styled.div``;

const DropdownMenuItem = ({ menuItem, currentTheme }) => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const handleDropdownToggle = (menuItemName) => {
    setOpenDropdown((prev) => (prev === menuItemName ? null : menuItemName));
  };

  return (
    <DropdownMenuItemWrap key={menuItem.id}>
      <MenuItemBtn
        menuItem={menuItem}
        handleDropdownToggle={handleDropdownToggle}
        currentTheme={currentTheme}
      />
      <DropdownDiv
        menuItem={menuItem}
        openDropdown={openDropdown}
        setOpenDropdown={setOpenDropdown}
        currentTheme={currentTheme}
        handleDropdownToggle={handleDropdownToggle}
      />
    </DropdownMenuItemWrap>
  );
};

export default DropdownMenuItem;
