import React from "react";

const Recipe = ({ completeProduct }) => {
  return (
    <table className="mt-2 table">
      <thead>
        <tr>
          <th scope="col" className="text-danger">
            Reteta
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {completeProduct.recipe
              ? completeProduct.recipe
              : "Nu exista reteta"}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Recipe;
