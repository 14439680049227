import React from "react";
import Select from "react-select";
import styled from "@emotion/styled";
import { handleCategoryChange } from "../../myFunctions";

const MyLabel = styled.label`
  color: red;
`;

const CategorySelectList = ({
  menuItem,
  menuItemIndex,
  name,
  setFieldValue,
  values,
  categoriesData,
  subcategoriesData,
}) => {
  return (
    <div>
      <MyLabel htmlFor={name}>
        Categorii pentru {`${menuItem.menuItemObj.name}`}
      </MyLabel>

      <Select
        isMulti
        name={name}
        options={menuItem.filteredCategoriesObj}
        value={menuItem.categories.map((cat) => {
          return {
            value: cat.categoryId,
            label: cat.categoryObj.name,
          };
        })}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={(selected) => {
          handleCategoryChange(
            selected,
            setFieldValue,
            values,
            menuItemIndex,
            categoriesData,
            subcategoriesData
          );
        }}
      />
    </div>
  );
};

export default CategorySelectList;
