import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import FormikField from "../../Components/FormikField";
import { editSubcategoryInitialValues } from "../../initialValuesFormik/subcategory/editSubcategoryInitialValues";
import { editSubcategoryValidationSchema } from "../../validationSchemas/subcategory/editSubcategoryValidationSchema";
import { useEditSubcategoryMutation } from "../../../myHooks/myMutations/editMutations/editSubcategoryMutation";
import { useSubcategoriesData } from "../../../Contexts/SubcategoriesDataContext";
import { getSubcategoryById } from "../../myFunctions";

const EditSubcategory = () => {
  const { id } = useParams();
  const [currentSubcategory, setCurrentSubcategory] = useState(null);
  const [initialValues, setInitialValues] = useState({
    name: "",
    path: "",
  });
  const mutation = useEditSubcategoryMutation(id);
  const { data: subcategoriesData } = useSubcategoriesData();

  useEffect(() => {
    if (subcategoriesData && id) {
      const currentSubcategory = getSubcategoryById(id, subcategoriesData);
      setCurrentSubcategory(currentSubcategory);
      if (currentSubcategory) {
        const initialValues = editSubcategoryInitialValues(currentSubcategory);
        setInitialValues(initialValues);
      }
    }
  }, [subcategoriesData]);
  console.log("currentSubcategory:", currentSubcategory);
  console.log("initialValues:", initialValues);
  const onSubmit = (data) => {
    mutation.mutate(data);
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center w-100 ">
      <h5>Editeaza subcategorie</h5>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={editSubcategoryValidationSchema}
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values }) => {
          return (
            <Form style={{ width: "60%", padding: "20px" }}>
              <FormikField
                name="name"
                labelText="Nume subcategorie"
                fieldType="text"
              />
              <FormikField name="path" labelText="Path" fieldType="text" />
              <button type="submit" className="btn btn-primary mt-2">
                Adauga
              </button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default EditSubcategory;
