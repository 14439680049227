import React, { useEffect, useState } from "react";
import { getSelectListCategoriesOptions } from "../../myFunctions";
import { useCategoriesData } from "../../../Contexts/CategoriesDataContext";
import { getCategoriesByMenuItemId } from "../../myFunctions";
import Select from "react-select";
import styled from "@emotion/styled";

const MySelectList = styled.div``;
const MyLabel = styled.label`
  color: red;
`;

const CategoriesSelectList = ({
  selectedMenuItemId,
  name,
  onCategoryChange,
}) => {
  const [categoriesByMenuId, setCategoriesByMenuId] = useState([]);
  const { data: categoriesData } = useCategoriesData();

  useEffect(() => {
    if (categoriesData) {
      const categoriesByMenuId = getCategoriesByMenuItemId(
        selectedMenuItemId,
        categoriesData
      );
      setCategoriesByMenuId(categoriesByMenuId);
    }
  }, [categoriesData, selectedMenuItemId]);

  const categoriesOptions = getSelectListCategoriesOptions(categoriesByMenuId);

  return (
    <div>
      {categoriesOptions && categoriesOptions.length > 0 && (
        <MySelectList>
          <MyLabel htmlFor={name}>Selecteaza categorie</MyLabel>
          <Select
            name={name}
            options={categoriesOptions}
            onChange={onCategoryChange}
          />
        </MySelectList>
      )}
    </div>
  );
};

export default CategoriesSelectList;
