import React from "react";
import { Outlet } from "react-router-dom";
import DesktopNavbar from "./Navigation/DesktopNavbar/DesktopNavbar";
import MobileNavbar from "./Navigation/MobileNavbar/MobileNavbar";

const AppLayout = ({ currentTheme }) => {
  return (
    <>
      <DesktopNavbar currentTheme={currentTheme} />
      <MobileNavbar currentTheme={currentTheme} />
      <div style={{ marginTop: "90px" }}>
        <Outlet />
      </div>
    </>
  );
};

export default AppLayout;
