import React from 'react'
import styled from "@emotion/styled";

const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-position: center center; /* Default: center of the image is focused */
  position: absolute;
  object-fit: cover;
  z-index: -100;
`;

const BackgroundImg = ({currentTheme}) => {
  return (
    <BackgroundImage
    src={currentTheme ? currentTheme.productPageBackgroundImgSrc : null}
    alt="background img Product Page"
  />
  )
}

export default BackgroundImg