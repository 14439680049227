import React from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { useMenuItemsData } from "../../../Contexts/MenuItemsDataContext";
import { useCategoriesData } from "../../../Contexts/CategoriesDataContext";
import { useDeleteCategory } from "../../../myHooks/myMutations/deleteMutations/deleteCategoryMutation";
const MenuItemCategoriesWrap = styled.div`
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  padding: 20px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const MenuItemName = styled.div`
  font-weight: 600;
  color: red;
`;
const CategoriesList = styled.div`
  width: 100%;
`;
const CategoryName = styled.div`
  margin-left: 10px;
`;
const CategoryPath = styled.div``;
const SingleCategory = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 0;
`;
const BtnsDiv = styled.div``;

const ListOfCategories = () => {
  const { data: menuItemsData } = useMenuItemsData();
  const { data: categoriesData } = useCategoriesData();

  const deleteCategory = useDeleteCategory();

  return (
    <div className="d-flex flex-column align-items-center justify-content-center w-100 ">
      <h5>Lista categorii</h5>
      <Link className="mb-4" to="/admin/adauga-categorie">
        Adaugă categorie
      </Link>

      {menuItemsData &&
        menuItemsData.map((menuItem) => {
          //Gasim categoriile asociate acestui menu item:
          const categoriesForCurrentMenuItem = categoriesData.filter(
            (categ) => {
              return categ.menuItemId === menuItem.id;
            }
          );

          return (
            <div key={menuItem.id} style={{ width: "70%" }}>
              {categoriesForCurrentMenuItem.length > 0 && (
                <MenuItemCategoriesWrap>
                  <MenuItemName>{menuItem.name}</MenuItemName>
                  <CategoriesList>
                    {categoriesForCurrentMenuItem.map((categ) => {
                      return (
                        <SingleCategory key={categ.id}>
                          <CategoryName>Nume : {categ.name}</CategoryName>
                          <CategoryPath>Path : {categ.path}</CategoryPath>
                          <BtnsDiv>
                            <button
                              className="btn me-2"
                              style={{
                                backgroundColor: " rgba(255,215,0,1)",
                              }}
                            >
                              <Link
                                to={`/admin/editeaza-categorie/${categ.id}`}
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                }}
                              >
                                Editează
                              </Link>
                            </button>
                            <button
                              className="btn me-2"
                              style={{
                                backgroundColor: "rgba(184,15,10,1)",
                                color: "white",
                              }}
                              onClick={() => deleteCategory(categ.id)}
                            >
                              Sterge
                            </button>
                          </BtnsDiv>
                        </SingleCategory>
                      );
                    })}
                  </CategoriesList>
                </MenuItemCategoriesWrap>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default ListOfCategories;
