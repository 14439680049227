import React from "react";

const RightImage = ({ currentTheme }) => {
  return (
    <>
      {currentTheme && currentTheme.mobileHeaderRightImgSrc && (
        <img
          style={{ height: "100%" }}
          src={currentTheme.mobileHeaderRightImgSrc}
          alt="right img "
        />
      )}
    </>
  );
};

export default RightImage;
