import { useQuery } from "@tanstack/react-query";
import { Axios } from "../../config";

const useGetCategoriesData = () => {
  const getListOfCategories = async () => {
    const { data } = await Axios.get("categories");
    return data;
  };

  return useQuery({
    queryKey: ["getListOfCategories"],
    queryFn: getListOfCategories,
  });
};

export default useGetCategoriesData;
