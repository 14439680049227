//*************GET SELECT LIST OPTIONS*************** */
export const getSelectListMenuItemsOptions = (menuItems) => {
  if (menuItems) {
    const transformedData = menuItems.map((menuItem) => {
      return { value: menuItem.id, label: menuItem.name };
    });
    return transformedData;
  } else return [];
};

export const getSelectListCategoriesOptions = (categories) => {
  if (categories) {
    const transformedData = categories.map((category) => {
      return { value: category.id, label: category.name };
    });
    return transformedData;
  } else return [];
};

export const getCategoriesByMenuItemId = (menuItemId, categories) => {
  if (categories && menuItemId) {
    const categoriesAfterMenuItemId = categories.filter(
      (category) => category.menuItemId === menuItemId
    );
    return categoriesAfterMenuItemId;
  } else return [];
};
//************GET ELEMENTS AFTER ID****************** */
export const getIngredientById = (id, categoriesData) => {
  const category = categoriesData.find(
    (category) => parseInt(category.id, 10) === parseInt(id, 10)
  );
  return category;
};

export const getMenuItemById = (id, menuItemsData) => {
  const menuItem = menuItemsData.find(
    (menuitem) => parseInt(menuitem.id, 10) === parseInt(id, 10)
  );
  return menuItem;
};
export const getCategoryById = (id, categoriesData) => {
  const categ = categoriesData.find(
    (category) => parseInt(category.id, 10) === parseInt(id, 10)
  );
  return categ;
};
export const getSubcategoryById = (id, subcategoriesData) => {
  const subcateg = subcategoriesData.find(
    (subcategory) => parseInt(subcategory.id, 10) === parseInt(id, 10)
  );
  return subcateg;
};

//*********************ADD PRODUCT********************* */
export const handleMenuItemChange = (
  selected,
  setFieldValue,
  values,
  menuItems,
  categoriesData
) => {
  //Cand adaugam un element:
  if (
    selected.length > values.selectedMenuItemsCategoriesSubcategories.length
  ) {
    //creez un nou element in values.selectedMenuItemsCategoriesSubcategories:

    //Gasesc menuItemId adaugat:
    const menuItemId = selected[selected.length - 1].value;
    //gasesc obiectul menuItem cu id menuItemId:
    const menuItemObj = menuItems.find(
      (menuItem) => menuItem.id === menuItemId
    );

    const filteredCategoriesObj = categoriesData
      .filter((category) => {
        return category.menuItemId === menuItemId;
      })
      .map((category) => {
        return {
          value: category.id,
          label: category.name,
        };
      });
    const selectedCategoriesIds = [];
    const categories = [];

    const elem = {
      menuItemId: menuItemId,
      menuItemObj: menuItemObj,
      selectedCategoriesIds: selectedCategoriesIds,
      filteredCategoriesObj: filteredCategoriesObj,
      categories: categories,
    };
    setFieldValue("selectedMenuItemsCategoriesSubcategories", [
      ...values.selectedMenuItemsCategoriesSubcategories,
      elem,
    ]);
  } else {
    //Cand stergem un element:

    const selectedMenuItemsCategoriesSubcategoriesAfterDeleteElem =
      values.selectedMenuItemsCategoriesSubcategories.filter((item) => {
        return selected
          .map((selectedMenuItem) => {
            return selectedMenuItem.value;
          })
          .includes(item.menuItemId);
      });
    setFieldValue(
      "selectedMenuItemsCategoriesSubcategories",
      selectedMenuItemsCategoriesSubcategoriesAfterDeleteElem
    );
  }
};
export const handleCategoryChange = (
  selected,
  setFieldValue,
  values,
  menuItemIndex,
  categoriesData,
  subcategoriesData
) => {
  //Daca adaugam:
  if (
    values.selectedMenuItemsCategoriesSubcategories[menuItemIndex]
      .selectedCategoriesIds == [] ||
    selected.length >
      values.selectedMenuItemsCategoriesSubcategories[menuItemIndex]
        .selectedCategoriesIds.length
  ) {
    //Cream un nou element in values.selectedMenuItemsCategoriesSubcategories:
    const selectedCategoriesIds = selected.map((category) => category.value);
    setFieldValue(
      `selectedMenuItemsCategoriesSubcategories[${menuItemIndex}].selectedCategoriesIds`,
      selectedCategoriesIds
    );
    //Adaug noua categorie la categories:

    //gasesc noul categoryId adaugat:
    const categoryId = selected[selected.length - 1].value;
    //gasesc obiectul category cu id categoryId:
    const categoryObj = categoriesData.find(
      (category) => category.id === categoryId
    );
    //gasesc subcategories pentru categoryId:
    const filteredSubcategoriesObj = subcategoriesData
      .filter((subcategory) => {
        return subcategory.categoryId === categoryId;
      })
      .map((subcategory) => {
        return {
          value: subcategory.id,
          label: subcategory.name,
        };
      });
    const categories =
      values.selectedMenuItemsCategoriesSubcategories[menuItemIndex].categories;
    const categoryElem = {
      categoryId: categoryId,
      categoryObj: categoryObj,
      filteredSubcategoriesObj: filteredSubcategoriesObj,
      subcategories: [],
      subcategoriesIds: [],
    };
    categories.push(categoryElem);
    setFieldValue(
      `selectedMenuItemsCategoriesSubcategories[${menuItemIndex}].categories`,
      categories
    );
  } else {
    //Daca stergem:
    const selectedMenuItemsCategoriesSubcategoriesAfterDeleteElem =
      values.selectedMenuItemsCategoriesSubcategories.map((item, index) => {
        if (index === menuItemIndex) {
          item.selectedCategoriesIds = selected.map(
            (category) => category.value
          );
          //sterg din categories elementul aferent idului sters
          item.categories = item.categories.filter((category) => {
            return selected
              .map((selectedCategory) => {
                return selectedCategory.value;
              })
              .includes(category.categoryId);
          });
          return item;
        } else {
          return item;
        }
      });
    setFieldValue(
      "selectedMenuItemsCategoriesSubcategories",
      selectedMenuItemsCategoriesSubcategoriesAfterDeleteElem
    );
  }
};

export const handleSubcategoryChange = (
  selected,
  setFieldValue,
  values,
  categoryIndex,
  menuItemIndex,
  subcategoriesData
) => {
  //daca adaugam:
  if (
    selected.length >
    values.selectedMenuItemsCategoriesSubcategories[menuItemIndex].categories[
      categoryIndex
    ].subcategoriesIds.length
  ) {
    //Cream un nou element in values.selectedMenuItemsCategoriesSubcategories:
    const selectedSubcategoriesIds = selected.map((subcategory) => {
      return subcategory.value;
    });
    setFieldValue(
      `selectedMenuItemsCategoriesSubcategories[${menuItemIndex}].categories[${categoryIndex}].subcategoriesIds`,
      selectedSubcategoriesIds
    );
    //gasesc id-ul adaugat si adaug la subcategories:
    const subcategoryId = selected[selected.length - 1].value;
    const subcategoryObj = subcategoriesData.find(
      (subcategory) => subcategory.id === subcategoryId
    );
    const subcategories =
      values.selectedMenuItemsCategoriesSubcategories[menuItemIndex].categories[
        categoryIndex
      ].subcategories;

    subcategories.push(subcategoryObj);
    setFieldValue(
      `selectedMenuItemsCategoriesSubcategories[${menuItemIndex}].categories[${categoryIndex}].subcategories`,
      subcategories
    );
  } else {
    //daca stergem :
    const selectedMenuItemsCategoriesSubcategoriesAfterDeleteElem =
      values.selectedMenuItemsCategoriesSubcategories.map((item, index) => {
        if (index === menuItemIndex) {
          item.categories[categoryIndex].subcategoriesIds = selected.map(
            (subcategory) => subcategory.value
          );
          //sterg din subcategories elementul aferent idului sters
          item.categories[categoryIndex].subcategories = item.categories[
            categoryIndex
          ].subcategories.filter((subcategory) => {
            return selected
              .map((selectedSubcategory) => {
                return selectedSubcategory.value;
              })
              .includes(subcategory.id);
          });
          return item;
        } else {
          return item;
        }
      });
    setFieldValue(
      "selectedMenuItemsCategoriesSubcategories",
      selectedMenuItemsCategoriesSubcategoriesAfterDeleteElem
    );
  }
};
