export const selectStyles = { control: (provided, state) => ({
    ...provided,
    outline: "none",
    width: "100%",
    border: `1px solid  rgb(196, 202, 206)`,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "black",
    fontSize: "16px",
    fontWeight: "400",
    marginLeft: "4px",
    width: "100%",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "5px",
  }),

  menu: (provided) => ({
    ...provided,
    backgroundColor: "white",
    zIndex: 999,
    width: "100%",
  }),
  menuList: (provided) => ({
    ...provided,
    padding: "10px",
    backgroundColor: "inherit",
    borderRadius: "5px",
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),}