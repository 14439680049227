import React from "react";

const PricesValabilityUM = ({ completeProduct }) => {
  return (
    <table className="mt-2 table">
      <thead>
        <tr>
          <th scope="col" className="text-danger">
            Preturi
          </th>
          <th scope="col" className="text-danger">
            Valabilitate
          </th>
          <th scope="col" className="text-danger">
            UM
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {completeProduct.priceByQnts &&
              completeProduct.unitOfMeasure &&
              completeProduct.priceByQnts.map((elem) => {
                return (
                  <div key={elem.id}>
                    {elem.quantity}
                    {completeProduct.unitOfMeasure}-{elem.price}ron
                  </div>
                );
              })}
          </td>
          <td>{completeProduct.valability && completeProduct.valability}</td>
          <td>
            {completeProduct.unitOfMeasure && completeProduct.unitOfMeasure}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default PricesValabilityUM;
