import React, { useRef } from "react";
import styled from "@emotion/styled";
import { Axios } from "../../../config";
import PreviewImage from "../../Components/PreviewImage";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useThemeImagesData } from "../../../Contexts/ThemeImagesDataContext";

const Wrap = styled.div`
  width: 90%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  padding: 20px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;
const ThemeImagesContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;
const AddThemeImageDiv = styled.div``;

const ListOfThemeImages = () => {
  const queryClient = useQueryClient();
  const { data: themeImagesData } = useThemeImagesData();
  const themeImagesRef = useRef(null);

  //------------Delete mutation----------------
  const deleteMutation = useMutation({
    mutationFn: (id) => Axios.delete(`/themeimages/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries(["getListOfThemeImages"]);
    },
    onError: (error) => {
      console.error("Error deleting image:", error);
    },
  });

  const deleteThemeImage = (id) => {
    if (window.confirm("Are you sure you want to delete this image?")) {
      deleteMutation.mutate(id);
    }
  };
  //-------------Post mutation-----------------
  const mutation = useMutation({
    mutationFn: (data) =>
      Axios.post("/themeimages", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),

    onError: (error) => {
      console.error("Error uploading files", error);
    },
  });
  //--------Formik values------------------
  const initialValues = {
    themeImages: [],
  };

  const validationSchema = Yup.object({
    themeImages: Yup.array()
      .min(1, "At least one file is required!")
      .required("Svg or Image is required!"),
  });

  const onSubmit = (data, { resetForm }) => {
    // Create FormData to submit with Axios
    const formData = new FormData();

    // Append each selected file
    data.themeImages.forEach((file) => {
      formData.append("themeImages", file);
    });

    // Trigger the mutation with formData
    mutation.mutate(formData, {
      onSuccess: () => {
        resetForm();
        queryClient.invalidateQueries(["getListOfThemeImages"]);
        console.log("Files uploaded successfully", data);
      },
    });
  };
  return (
    <div className="d-flex justify-content-center w-100">
      <Wrap>
        <div className="d-flex flex-column align-items-center ">
          <h5>Imagini teme</h5>
          <ThemeImagesContainer>
            {themeImagesData.map((item) => {
              return (
                <div key={item.id} className="d-flex flex-column p-2">
                  <img
                    src={item.src}
                    alt="item"
                    style={{ width: "100px", border: "1px solid grey" }}
                  />
                  <button
                    className="btn me-2"
                    style={{
                      backgroundColor: "rgba(184,15,10,1)",
                      color: "white",
                    }}
                    onClick={() => deleteThemeImage(item.id)}
                  >
                    Sterge
                  </button>
                </div>
              );
            })}
          </ThemeImagesContainer>
        </div>
        <AddThemeImageDiv>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ values, setFieldValue, resetForm }) => {
              return (
                <Form className="w-100">
                  <ErrorMessage
                    name="themeImages"
                    component="div"
                    className="alert alert-danger"
                  />
                  <div className=" w-100 d-flex flex-column justify-content-center align-items-center">
                    <input
                      hidden
                      multiple
                      ref={themeImagesRef}
                      name="themeImages"
                      type="file"
                      onChange={(e) => {
                        setFieldValue(
                          "themeImages",
                          Array.from(e.target.files)
                        );
                      }}
                    />
                    <div className=" d-flex flex-wrap">
                      {values.themeImages &&
                        values.themeImages.map((file, index) => (
                          <PreviewImage key={index} file={file} />
                        ))}
                    </div>
                    <div className="d-flex  justify-content-around w-25 mt-4">
                      <button
                        className="btn btn-info"
                        type="button"
                        onClick={() => {
                          themeImagesRef.current.click();
                        }}
                      >
                        Upload
                      </button>
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </AddThemeImageDiv>
        {/* <ThemeImagesContainer>
          {themeImages.map((item) => {
            return (
              <div key={item.id} className="d-flex flex-column p-2">
                <img
                  src={item.src}
                  alt="item"
                  style={{ width: "100px", border: "1px solid grey" }}
                />
                <button
                  className="btn me-2"
                  style={{
                    backgroundColor: "rgba(184,15,10,1)",
                    color: "white",
                  }}
                  onClick={() => deleteThemeImage(item.id)}
                >
                  Sterge
                </button>
              </div>
            );
          })}
        </ThemeImagesContainer> */}
      </Wrap>
    </div>
  );
};

export default ListOfThemeImages;
