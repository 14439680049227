import React, { useEffect, useState } from "react";
import { getSelectListMenuItemsOptions } from "../../myFunctions";
import { useMenuItemsData } from "../../../Contexts/MenuItemsDataContext";
import { useCategoriesData } from "../../../Contexts/CategoriesDataContext";
import Select from "react-select";
import styled from "@emotion/styled";

const MySelectList = styled.div``;
const MyLabel = styled.label`
  color: red;
`;

const MenuItemsSelectList = ({ value, onMenuItemChange, name }) => {
  const [menuItemsOptions, setMenuItemsOptions] = useState([]);
  const { data: menuItemsData } = useMenuItemsData();
  const { data: categories } = useCategoriesData();

  useEffect(() => {
    if (menuItemsData && categories) {
      const menuItemsWithCategories = menuItemsData.filter((menuItem) =>
        categories.some((category) => category.menuItemId === menuItem.id)
      );
      const options = getSelectListMenuItemsOptions(menuItemsWithCategories);
      setMenuItemsOptions(options);
    }
  }, [menuItemsData, categories]);
  return (
    <>
      {menuItemsOptions && menuItemsOptions.length > 0 && (
        <MySelectList>
          <MyLabel htmlFor={name}>Selecteaza menu item</MyLabel>
          <Select
            name={name}
            options={menuItemsOptions}
            value={value}
            onChange={onMenuItemChange}
          />
        </MySelectList>
      )}
    </>
  );
};

export default MenuItemsSelectList;
