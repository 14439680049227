import React from "react";
import styled from "@emotion/styled";

const DropdownButton = styled.button`
  background-color: inherit;
  border: none;
  padding: 12px 16px;
  margin: 0;
  color: inherit;
  font: inherit;
  cursor: pointer;
  font-weight: 500;
  font-size: 18px;
`;

const DropdownBtn = ({ currentTheme, handleDropdownToggle, menuItem }) => {
  return (
    <DropdownButton onClick={() => handleDropdownToggle(menuItem.name)}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={currentTheme ? `${currentTheme.sideNavMenuItemIconSrc}` : null}
          style={{ marginRight: "5px", width: "15px" }}
        />
        <div
          style={{
            color: currentTheme
              ? `${currentTheme.sideNavMenuItemColor}`
              : "white",
          }}
        >
          {menuItem.name}
        </div>
        <i
          className="fa fa-caret-down ms-1 "
          style={{
            color: currentTheme
              ? `${currentTheme.sideNavMenuItemColor}`
              : "white",
          }}
        ></i>
      </div>
    </DropdownButton>
  );
};

export default DropdownBtn;
