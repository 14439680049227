import { useQuery } from "@tanstack/react-query";
import { Axios } from "../../config";

const useGetIngredientsData = () => {
  const getListOfIngredients = async () => {
    const { data } = await Axios.get("ingredients");
    return data;
  };

  return useQuery({
    queryKey: ["getListOfIngredients"],
    queryFn: getListOfIngredients,
  });
};

export default useGetIngredientsData;
