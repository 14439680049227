import React from "react";
import styled from "@emotion/styled";

const Label = styled.div`
  color: red;
`;
const VisibilityDiv = ({ name, visibility, setVisibility }) => {
  return (
    <div className="mb-3">
      <Label htmlFor={name} className="form-Label me-3 ">
        Bifați dacă doriți ca produsul să fie invizibil utilizatorilor:
      </Label>
      <input
        name={name}
        type="checkbox"
        checked={visibility ? false : true}
        onChange={() => setVisibility(!visibility)}
      />
    </div>
  );
};

export default VisibilityDiv;
