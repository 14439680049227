// MenuItemsContext.js
import React, { createContext, useContext } from "react";
import useGetMenuItemsData from "../myHooks/menuItem/useGetMenuItemsData";

// Creează contextul
const MenuItemsDataContext = createContext();

// Creează un custom hook pentru a folosi contextul
export const useMenuItemsData = () => {
  return useContext(MenuItemsDataContext);
};

// Creează providerul pentru context
export const MenuItemsDataProvider = ({ children }) => {
  const {
    data: menuItemsData,
    isLoading: isLoadingMenuItemsData,
    isError: isErrorMenuItemsData,
    error: errorMenuItemsData,
  } = useGetMenuItemsData();

  return (
    <MenuItemsDataContext.Provider
      value={{
        data: menuItemsData,
        isLoading: isLoadingMenuItemsData,
        isError: isErrorMenuItemsData,
        error: errorMenuItemsData,
      }}
    >
      {children}
    </MenuItemsDataContext.Provider>
  );
};
