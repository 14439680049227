import React from "react";
import styled from "@emotion/styled";
import DropdownBtn from "./DropdownBtn";
import { Link } from "react-router-dom";

const DropdownSidenav = styled.div``;
const DropdownContent = styled.div`
  margin-left: 20px;
  width: 85%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 8px 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  border-left: 1px solid rgba(0, 0, 0, 0.4);
  border-right: 1px solid rgba(0, 0, 0, 0.4);
`;
const SidenavLink = styled(Link)`
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
  text-decoration: none;
  padding: 8px 12px;
  text-decoration: none;
  display: block;
  text-align: left;
`;
const DropdownMenuItem = ({
  menuItem,
  currentTheme,
  sideNav,
  setSideNav,
  categoriesData,
  handleDropdownToggle,
  openDropdown,
}) => {
  const categoriesAfterMenuItem = categoriesData.filter((category) => {
    return category.menuItemId === menuItem.id;
  });
  return (
    <DropdownSidenav>
      <DropdownBtn
        currentTheme={currentTheme}
        handleDropdownToggle={handleDropdownToggle}
        menuItem={menuItem}
      />
      {openDropdown === menuItem.name && (
        <DropdownContent
          style={{
            backgroundColor: currentTheme
              ? `${currentTheme.dropdownDivColor}`
              : "white",
          }}
        >
          {categoriesAfterMenuItem &&
            categoriesAfterMenuItem.map((category) => {
              return (
                <SidenavLink
                  key={category.id}
                  to={`${menuItem.path}/${category.path}`}
                  onClick={() => {
                    handleDropdownToggle(null);
                    setSideNav(!sideNav);
                  }}
                  style={{
                    color: currentTheme
                      ? `${currentTheme.dropdownDivTextColor}`
                      : "white",
                  }}
                >
                  {category.name}
                </SidenavLink>
              );
            })}
        </DropdownContent>
      )}
    </DropdownSidenav>
  );
};

export default DropdownMenuItem;
