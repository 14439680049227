import React, { useState } from "react";
import styled from "@emotion/styled";

const LoginFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #6a11cb, #2575fc);
  font-family: Arial, sans-serif;
`;

const FormWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  padding: 40px 30px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 400px;
`;

const Title = styled.h2`
  margin-bottom: 20px;
  color: #333333;
  text-align: center;
`;

const InputField = styled.input`
  width: 100%;
  padding: 12px 15px;
  margin-bottom: 20px;
  border: 1px solid #dddddd;
  border-radius: 8px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s;

  &:focus {
    border-color: #2575fc;
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 12px 15px;
  border: none;
  border-radius: 8px;
  background: #2575fc;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background: #6a11cb;
  }
`;

const StyledLink = styled.a`
  display: block;
  margin-top: 15px;
  color: #2575fc;
  text-align: center;
  text-decoration: none;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }
`;

const LoginForm = ({ setLogged }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (username === "adminbumbunnyca" && password === "bumbunnyca!85") {
      setLogged(true);
    } else {
      alert("username sau parola gresita");
    }
  };
  return (
    <LoginFormContainer>
      <FormWrapper>
        <Title>Welcome Back</Title>
        <form>
          <InputField
            name="username"
            placeholder="Username"
            onChange={(e) => setUsername(e.target.value)}
          />
          <InputField
            name="password"
            type="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <SubmitButton type="submit" onClick={handleSubmit}>
            Log In
          </SubmitButton>
        </form>
        <StyledLink href="#">Forgot your password?</StyledLink>
      </FormWrapper>
    </LoginFormContainer>
  );
};

export default LoginForm;
