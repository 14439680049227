import React, { useEffect, useState } from "react";
import { getSelectListMenuItemsOptions } from "../../myFunctions";
import { handleMenuItemChange } from "../../myFunctions";
import Select from "react-select";
import styled from "@emotion/styled";

const MyLabel = styled.label`
  color: red;
`;

const MenuItemsSelectList = ({
  setFieldValue,
  values,
  name,
  menuItemsData,
  categoriesData,
}) => {
  const [menuItemsOptions, setMenuItemsOptions] = useState([]);

  useEffect(() => {
    if (menuItemsData) {
      const options = getSelectListMenuItemsOptions(menuItemsData);
      setMenuItemsOptions(options);
    }
  }, [menuItemsData]);
  return (
    <>
      {menuItemsOptions && menuItemsOptions.length > 0 && (
        <div>
          <MyLabel htmlFor={name}>Selecteaza menu item</MyLabel>
          <Select
            isMulti
            name={name}
            options={menuItemsOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(selected) =>
              handleMenuItemChange(
                selected,
                setFieldValue,
                values,
                menuItemsData,
                categoriesData
              )
            }
          />
        </div>
      )}
    </>
  );
};

export default MenuItemsSelectList;
