import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Axios } from "../../../config";

export const useAddMenuItemMutation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async (newMenuItem) =>
      await Axios.post("menuitems", newMenuItem).then((res) => {
        console.log(res.data);
      }),
    onMutate: async (newMenuItem) => {
      await queryClient.cancelQueries(["getListOfMenuItems"]);

      const previousMenuItems = queryClient.getQueryData([
        "getListOfMenuItems",
      ]);

      queryClient.setQueryData(["getListOfMenuItems"], (oldMenuItems) => [
        ...(oldMenuItems || []),
        { id: Date.now(), ...newMenuItem },
      ]);

      return { previousMenuItems };
    },
    onError: (err, newMenuItem, context) => {
      queryClient.setQueryData(
        ["getListOfMenuItems"],
        context.previousMenuItems
      );
      console.error("Eroare la adăugarea menu item:", err);
    },
    onSuccess: () => {
      navigate("/admin/lista-menu-items");
      queryClient.invalidateQueries(["getListOfMenuItems"]);
    },
  });
};
