import { useQuery } from "@tanstack/react-query";
import { Axios } from "../../config";

const useGetProductContainerImagesData = () => {
  const getListOfProductContainerImages = async () => {
    const { data } = await Axios.get("productcontainerimages");
    return data;
  };

  return useQuery({
    queryKey: ["getListOfProductContainerImages"],
    queryFn: getListOfProductContainerImages,
  });
};

export default useGetProductContainerImagesData;
