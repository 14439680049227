import React from "react";

const Specifications = ({ completeProduct }) => {
  return (
    <table className="mt-2 table">
      <thead>
        <tr>
          <th scope="col" className="text-danger">
            Specificatii produs
          </th>
        </tr>
      </thead>
      <tbody>
        {completeProduct.productSpecifications &&
          completeProduct.productSpecifications.map((specification) => {
            return (
              <tr key={specification.id}>
                <td>
                  <div>
                    {specification.title} : {specification.specification}
                  </div>
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default Specifications;
