import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import ClientNavbarLayout from "./Components/ClientNavbarLayout";
import { useMenuItemsData } from "../Contexts/MenuItemsDataContext";
import { useCategoriesData } from "../Contexts/CategoriesDataContext";
import ProductListPage from "./Pages/ProductListPage";
import { SubcategoriesDataProvider } from "../Contexts/SubcategoriesDataContext";
import ProductPage from "./Pages/ProductPage";

const ClientViews = ({ currentTheme }) => {
  const { data: menuItemsData } = useMenuItemsData();
  const { data: categoriesData } = useCategoriesData();
  return (
    <Routes>
      <Route
        path="/"
        element={<ClientNavbarLayout currentTheme={currentTheme} />}
      >
        <Route index element={<Home currentTheme={currentTheme} />} />
        <Route
          path="produse/:id"
          element={<ProductPage currentTheme={currentTheme} />}
        />
        {/* ------------------Rute pentru ProductListPage----------------- */}
        {menuItemsData
          .filter((menuItem) => menuItem.hasdropdown === "no")
          .map((menuItem) => (
            <Route
              key={menuItem.id}
              path={menuItem.path}
              element={
                <SubcategoriesDataProvider>
                  <ProductListPage
                    menuItem={menuItem}
                    currentTheme={currentTheme}
                    productListPageTitle={menuItem.name}
                  />
                </SubcategoriesDataProvider>
              }
            />
          ))}

        {menuItemsData
          .filter((menuItem) => menuItem.hasdropdown === "yes")
          .flatMap((menuItem) => {
            const relatedCategories = categoriesData.filter(
              (category) => category.menuItemId === menuItem.id
            );
            return relatedCategories.map((category) => (
              <Route
                key={category.id}
                path={`${menuItem.path}/${category.path}`}
                element={
                  <SubcategoriesDataProvider>
                    <ProductListPage
                      menuItem={menuItem}
                      category={category}
                      currentTheme={currentTheme}
                      productListPageTitle={category.name}
                    />
                  </SubcategoriesDataProvider>
                }
              />
            ));
          })}
      </Route>
    </Routes>
  );
};

export default ClientViews;
