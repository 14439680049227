import React from "react";
import { Routes, Route } from "react-router-dom";
import AdminNavbarLayout from "./Components/AdminNavbarLayout";
import ListOfIngredients from "./Pages/Ingredient/ListOfIngredients";
import AddIngredient from "./Pages/Ingredient/AddIngredient";
import EditIngredient from "./Pages/Ingredient/EditIngredient";
import ListOfMenuItems from "./Pages/MenuItem/ListOfMenuItems";
import AddMenuItem from "./Pages/MenuItem/AddMenuItem";
import EditMenuItem from "./Pages/MenuItem/EditMenuItem";
import ListOfCategories from "./Pages/Category/ListOfCategories";
import AddCategory from "./Pages/Category/AddCategory";
import EditCategory from "./Pages/Category/EditCategory";
import ListOfSubcategories from "./Pages/Subcategory/ListOfSubcategories";
import AddSubcategory from "./Pages/Subcategory/AddSubcategory";
import EditSubcategory from "./Pages/Subcategory/EditSubcategory";
import ListOfThemes from "./Pages/Theme/ListOfThemes";
import AddTheme from "./Pages/Theme/AddTheme";
import EditTheme from "./Pages/Theme/EditTheme";
import ListOfThemeImages from "./Pages/ThemeImage/ListOfThemeImages";
import { IngredientsDataProvider } from "../Contexts/IngredientsDataContext";
import { SubcategoriesDataProvider } from "../Contexts/SubcategoriesDataContext";
import AddProduct from "./Pages/Product/AddProduct";
import EditProduct from "./Pages/Product/EditProduct";
import ListOfProducts from "./Pages/Product/ListOfProducts";
import SingleProduct from "./Pages/Product/SingleProduct";
import ListOfProductContainerImages from "./Components/ProductContainerImages/ListOfProductContainerImages";
import { ProductContainerImagesDataProvider } from "../Contexts/ProductContainerImagesDataContext";

const AdminViews = () => {
  return (
    <Routes>
      <Route path="/" element={<AdminNavbarLayout />}>
        <Route index element={<ListOfProducts />} />
        {/* ------------------MENU ITEMS------------------ */}
        <Route path="lista-menu-items" element={<ListOfMenuItems />} />
        <Route path="adauga-menu-item" element={<AddMenuItem />} />
        <Route path="editeaza-menu-item/:id" element={<EditMenuItem />} />

        {/* ------------------INGREDIENTS------------------ */}
        <Route
          path="lista-ingrediente"
          element={
            <IngredientsDataProvider>
              <ListOfIngredients />
            </IngredientsDataProvider>
          }
        />
        <Route path="adauga-ingredient" element={<AddIngredient />} />
        <Route
          path="editeaza-ingredient/:id"
          element={
            <IngredientsDataProvider>
              <EditIngredient />
            </IngredientsDataProvider>
          }
        />

        {/* ------------------CATEGORII------------------ */}
        <Route path="lista-categorii" element={<ListOfCategories />} />
        <Route path="adauga-categorie" element={<AddCategory />} />
        <Route path="editeaza-categorie/:id" element={<EditCategory />} />

        {/* ------------------SUBCATEGORII------------------ */}
        <Route
          path="lista-subcategorii"
          element={
            <SubcategoriesDataProvider>
              <ListOfSubcategories />
            </SubcategoriesDataProvider>
          }
        />
        <Route path="adauga-subcategorie" element={<AddSubcategory />} />
        <Route
          path="editeaza-subcategorie/:id"
          element={
            <SubcategoriesDataProvider>
              <EditSubcategory />
            </SubcategoriesDataProvider>
          }
        />

        {/* ------------------TEME------------------ */}
        <Route path="lista-teme" element={<ListOfThemes />} />
        <Route path="adauga-tema" element={<AddTheme />} />
        <Route path="editeaza-tema/:id" element={<EditTheme />} />

        {/* ------------------IMAGINI TEME------------------ */}
        <Route path="lista-imagini-teme" element={<ListOfThemeImages />} />
        {/* ------------------IMAGINI RECIPIENTE PRODUSE------------------ */}
        <Route
          path="lista-imagini-recipiente"
          element={
            <ProductContainerImagesDataProvider>
              <ListOfProductContainerImages />
            </ProductContainerImagesDataProvider>
          }
        />

        {/* ------------------PRODUSE------------------ */}
        <Route path="lista-produse" element={<ListOfProducts />} />
        <Route
          path="adauga-produs"
          element={
            <IngredientsDataProvider>
              <SubcategoriesDataProvider>
                <AddProduct />
              </SubcategoriesDataProvider>
            </IngredientsDataProvider>
          }
        />
        <Route
          path="editeaza-produs/:id"
          element={
            <IngredientsDataProvider>
              <SubcategoriesDataProvider>
                <EditProduct />
              </SubcategoriesDataProvider>
            </IngredientsDataProvider>
          }
        />
        <Route path="vezi-produs/:id" element={<SingleProduct />} />
      </Route>
    </Routes>
  );
};

export default AdminViews;
