import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

const AdminMenuwrap = styled.div`
  width: 100%;
  height: 80px;
  background-color: rgb(0, 0, 51);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;

const AdminNav = styled.div`
  //border: 2px solid red;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const LinkWrap = styled.div``;

const CustomLink = styled(Link)`
  text-decoration: none;
  color: white;
  padding: 10px;
  font-size: 17px;
`;

const AdminMenu = () => {
  return (
    <AdminMenuwrap>
      <AdminNav>
        <LinkWrap>
          <CustomLink to="/admin/lista-produse">Produse</CustomLink>
        </LinkWrap>
        <LinkWrap>
          <CustomLink to="/admin/lista-menu-items">Menu items</CustomLink>
        </LinkWrap>
        <LinkWrap>
          <CustomLink to="/admin/lista-categorii">Categorii</CustomLink>
        </LinkWrap>
        <LinkWrap>
          <CustomLink to="/admin/lista-subcategorii">Subcategorii</CustomLink>
        </LinkWrap>
        <LinkWrap>
          <CustomLink to="/admin/lista-ingrediente">Ingrediente</CustomLink>
        </LinkWrap>
        <LinkWrap>
          <CustomLink to="/admin/lista-teme">Teme</CustomLink>
        </LinkWrap>
        <LinkWrap>
          <CustomLink to="/admin/lista-imagini-teme">Imagini teme</CustomLink>
        </LinkWrap>
        <LinkWrap>
          <CustomLink to="/admin/lista-imagini-recipiente">
            Imagini recipiente
          </CustomLink>
        </LinkWrap>
      </AdminNav>
    </AdminMenuwrap>
  );
};

export default AdminMenu;
