import React from "react";
import styled from "@emotion/styled";

const CurrentThemeImages = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
`;
const ImagesDiv = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: end;
  padding: 10px 0 10px 0;
  gap: 15px;
`;
const SingleImageDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const ImageField = styled.div`
  color: red;
`;
const CurrentThemeImagesSection = ({ currentTheme, section }) => {
  return (
    <CurrentThemeImages>
      <h6 style={{ color: "blue" }}>IMAGINI CURENTE : </h6>
      <ImagesDiv>
        {section.sectionImages
          .map((image) => {
            return image.imageFieldName;
          })
          .map((fieldName) => {
            const themeImageSrc = currentTheme[fieldName];
            return (
              <SingleImageDiv>
                <img
                  src={themeImageSrc}
                  alt="current theme image"
                  style={{ width: "100px", border: "1px solid grey" }}
                />
                <ImageField>{fieldName}</ImageField>
              </SingleImageDiv>
            );
          })}
      </ImagesDiv>
    </CurrentThemeImages>
  );
};

export default CurrentThemeImagesSection;
