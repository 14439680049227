import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Axios } from "../../../config";

export const useAddIngredient = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async (ingredientData) => {
      const response = await Axios.post("ingredients", ingredientData);
      return response.data;
    },
    onMutate: async (newIngredient) => {
      await queryClient.cancelQueries(["getListOfIngredients"]);
      const previousIngredients = queryClient.getQueryData([
        "getListOfIngredients",
      ]);
      queryClient.setQueryData(["getListOfIngredients"], (oldData) => [
        ...(oldData || []),
        { id: Date.now(), ...newIngredient },
      ]);
      return { previousIngredients };
    },
    onError: (error, newIngredient, context) => {
      queryClient.setQueryData(
        ["getListOfIngredients"],
        context.previousIngredients
      );
      console.error("Eroare la adăugarea ingredientului:", error);
    },
    onSuccess: () => {
      navigate("/admin/lista-ingrediente");
      queryClient.invalidateQueries(["getListOfIngredients"]);
    },
  });
};
