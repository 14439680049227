import React from "react";

const MenuItemsCategoriesSubcategories = ({ completeProduct }) => {
  return (
    <table className="mt-2 table">
      <thead>
        <tr>
          <th scope="col" className="text-danger">
            Menu items
          </th>
          <th scope="col" className="text-danger">
            Categorii
          </th>
          <th scope="col" className="text-danger">
            Subcategorii
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {completeProduct.menuItems &&
              completeProduct.menuItems.map((menuItem) => {
                return <div key={menuItem.id}>{menuItem.name}</div>;
              })}
          </td>
          <td>
            {completeProduct.categories &&
              completeProduct.categories.map((category) => {
                return <div key={category.id}>{category.name}</div>;
              })}
          </td>
          <td>
            {completeProduct.subcategories &&
              completeProduct.subcategories.map((subcategory) => {
                return <div key={subcategory.id}>{subcategory.name}</div>;
              })}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default MenuItemsCategoriesSubcategories;
