import React, { useState } from "react";
import { Link } from "react-router-dom";
import DarkMask from "./DarkMask";
import CloseBtn from "./CloseBtn";
import styled from "@emotion/styled";
import SideMenu from "./SideMenu";

const Sidenavbar = styled.div`
  width: 300px;
  background-color: rgba(0, 0, 0, 0.7);
  height: 100vh;
  position: absolute;
  top: 90px;
  right: 0;
  bottom: 0;
  z-index: 2;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: end;
`;

const Sidenav = ({ currentTheme, setSideNav, sideNav }) => {
  return (
    <>
      <DarkMask />
      <Sidenavbar>
        <CloseBtn
          currentTheme={currentTheme}
          setSideNav={setSideNav}
          sideNav={sideNav}
        />
        <SideMenu
          currentTheme={currentTheme}
          setSideNav={setSideNav}
          sideNav={sideNav}
        />
      </Sidenavbar>
      ;
    </>
  );
};

export default Sidenav;
