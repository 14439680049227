import useGetProductsConditionally from "../../myHooks/product/useGetCompleteProductsConditionally";
import React, { useState, useEffect } from "react";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { Row, Col, Container } from "react-bootstrap";
import SingleCard from "../Components/ProductListPage/SingleCard";
import MySelectList from "../Components/ProductListPage/MySelectList/MySelectList";
import LoadingSpinner from "../Components/LoadingSpinner";

const appear = keyframes`
from{
  opacity:0;}
  to{
    opacity:1;}
  
  `;

const Wrap = styled.div`
  width: 100%;
  padding: 25px;
  margin-top: 90px;
  min-height: calc(100vh - 90px);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${appear} 2s ease forwards;
  @media screen and (max-width: 500px) {
    padding: 25px 10px;
  }
`;
const ContainerWrap = styled.div`
  border-radius: 9px;
  width: 70%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: ${appear} 2.5s ease forwards;
  @media screen and (max-width: 600px) {
    width: 90%;
  }
`;
const BackgroundMask = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: -99;
  top: 90px;
`;
const PageTitle = styled.div`
  text-align: center;
  padding: 30px 0 30px 0;
  font-size: 25px;
  font-weight: 600;
  @media screen and (max-width: 600px) {
    padding: 20px 0 20px 0;
    font-size: 23px;
  }
`;
const ProductListContainer = styled(Container)``;
const ProductListPage = ({
  menuItem,
  category,
  currentTheme,
  productListPageTitle,
}) => {
  const [filteredProducts, setFilteredProducts] = useState([]);
  const {
    data: products,
    isLoading,
    error,
  } = useGetProductsConditionally(menuItem.id, category?.id);
  console.log("products", products);
  useEffect(() => {
    setFilteredProducts(products);
  }, [products]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div>Error loading products</div>;
  }

  return (
    <Wrap>
      <BackgroundMask
        style={{
          backgroundColor: currentTheme
            ? `${currentTheme.productListPageBackgroundMaskColor}`
            : " rgba(0,0,0,0.1)",
        }}
      />
      <div
        style={{
          backgroundImage: currentTheme
            ? `url(${currentTheme.productListPageBackgroundImageSrc})`
            : "",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",

          position: "fixed",
          width: "100%",
          height: "100vh",
          zIndex: -100,
          top: 90,
        }}
      />

      <ContainerWrap
        style={{
          backgroundColor: currentTheme
            ? currentTheme.productsContainerBackgroundColor
            : "rgba(243, 218, 214, 1)",
          border: currentTheme
            ? `1px solid ${currentTheme.productsContainerBorder}`
            : "grey",
        }}
      >
        <PageTitle>{productListPageTitle}</PageTitle>
        <MySelectList
          category={category}
          menuItem={menuItem}
          setFilteredProducts={setFilteredProducts}
          products={products}
          currentTheme={currentTheme}
        />

        <ProductListContainer>
          <Row className=" justify-content-center" xs="auto">
            {filteredProducts &&
              filteredProducts.map((item) => {
                console.log("filertedProducts", filteredProducts);
                return (
                  <div key={item.id}>
                    {item.visibility == 1 && (
                      <Col className="d-flex justify-content-center">
                        <SingleCard
                          currentProduct={item}
                          currentTheme={currentTheme}
                        />
                      </Col>
                    )}
                  </div>
                );
              })}
          </Row>
        </ProductListContainer>
      </ContainerWrap>
    </Wrap>
  );
};

export default ProductListPage;
