import React from "react";
import styled from "@emotion/styled";

const Gallery = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const GalleryWrap = styled.div`
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;
const style = {
  Image: {
    width: "150px",
    padding: "6px",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
  },
};
const GallerySection = () => {
  return (
    <Gallery>
      <GalleryWrap>
        <img src="/images/home-page-images-section/1.jpg" style={style.Image} />
        <img src="/images/home-page-images-section/2.jpg" style={style.Image} />
        <img src="/images/home-page-images-section/3.jpg" style={style.Image} />
        <img src="/images/home-page-images-section/4.jpg" style={style.Image} />
        <img src="/images/home-page-images-section/5.jpg" style={style.Image} />
        <img src="/images/home-page-images-section/6.jpg" style={style.Image} />
        <img src="/images/home-page-images-section/7.jpg" style={style.Image} />
        <img src="/images/home-page-images-section/8.jpg" style={style.Image} />
      </GalleryWrap>
      <GalleryWrap>
        <img src="/images/home-page-images-section/a.jpg" style={style.Image} />
        <img src="/images/home-page-images-section/b.jpg" style={style.Image} />
        <img src="/images/home-page-images-section/c.jpg" style={style.Image} />
        <img src="/images/home-page-images-section/d.jpg" style={style.Image} />
        <img src="/images/home-page-images-section/e.jpg" style={style.Image} />
        <img src="/images/home-page-images-section/f.jpg" style={style.Image} />
        <img src="/images/home-page-images-section/g.jpg" style={style.Image} />
        <img src="/images/home-page-images-section/i.jpg" style={style.Image} />
      </GalleryWrap>
    </Gallery>
  );
};

export default GallerySection;
