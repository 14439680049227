import { useQuery } from "@tanstack/react-query";
import { Axios } from "../../config";

const useGetThemeImagesData = () => {
  const getListOfThemeImages = async () => {
    const { data } = await Axios.get("themeimages");
    return data;
  };

  return useQuery({
    queryKey: ["getListOfThemeImages"],
    queryFn: getListOfThemeImages,
  });
};

export default useGetThemeImagesData;
