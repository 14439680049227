import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

const DetaliiButton = styled(Link)`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-top: 10px;
`;

const DetailsBtn = ({ currentProduct, currentTheme }) => {
  return (
    <DetaliiButton
      to={`/produse/${currentProduct.id}`}
      style={{
        backgroundColor: currentTheme
          ? ` ${currentTheme.cardBtnBackgroundColor}`
          : "rgba(143, 30, 53, 255)",
      }}
    >
      Detalii
    </DetaliiButton>
  );
};

export default DetailsBtn;
