import React from "react";
import NoDropdownMenuItem from "./NoDropdownMenuItem";
import DropdownMenuItem from "./DropdownMenuItem";

const Last3MenuItems = ({ menuItems, currentTheme }) => {
  return (
    <>
      {menuItems
        .filter((menuitem) => {
          return menuitem.id > 3;
        })
        .map((menuItem) => {
          if (menuItem.hasdropdown === "no") {
            return (
              <NoDropdownMenuItem
                key={menuItem.id}
                menuItem={menuItem}
                currentTheme={currentTheme}
              />
            );
          } else {
            return (
              <DropdownMenuItem
                key={menuItem.id}
                menuItem={menuItem}
                currentTheme={currentTheme}
              />
            );
          }
        })}
    </>
  );
};

export default Last3MenuItems;
