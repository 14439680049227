import React, { useState, useRef } from "react";
import { Formik, Form } from "formik";
import ImageDiv from "../../Components/AddProduct/ImageDiv";
import SelectListsDiv from "../../Components/AddProduct/SelectListsDiv";
import FormikField from "../../Components/FormikField";
import UseInstructionsDiv from "../../Components/AddProduct/UseInstructionsDiv";
import SpecificationsDiv from "../../Components/AddProduct/SpecificationsDiv";
import WarningsDiv from "../../Components/AddProduct/WarningsDiv";
import DescriptionsDiv from "../../Components/AddProduct/DescriptionsDiv";
import PricesByQntDiv from "../../Components/AddProduct/PricesByQntDiv";
import SelectIngredientsDiv from "../../Components/AddProduct/SelectIngredientsDiv";
import VisibilityDiv from "../../Components/AddProduct/VisibilityDiv";
import { addProductInitialValues } from "../../initialValuesFormik/product/addProductInitialValues";
import { useAddProduct } from "../../../myHooks/myMutations/addMutations/addProductMutation";
const AddProduct = () => {
  const fileRef = useRef(null);
  const [visibility, setVisibility] = useState(true);
  const initialValues = addProductInitialValues(visibility);
  const mutation = useAddProduct();
  const onSubmit = (data) => {
    mutation.mutate(data);
  };
  return (
    <div className="d-flex  flex-column align-items-center w-100">
      <h5>Adauga produs :</h5>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, setFieldValue }) => {
          console.log("values:", values);
          return (
            <Form style={{ width: "80%" }}>
              <SelectListsDiv setFieldValue={setFieldValue} values={values} />
              <FormikField
                name="name"
                labelText="Nume produs"
                fieldType="textarea"
                rows={1}
              />
              <ImageDiv
                name="image"
                fileRef={fileRef}
                setFieldValue={setFieldValue}
                values={values}
              />
              <FormikField
                name="recipe"
                labelText="Reteta"
                fieldType="textarea"
                rows={6}
              />
              <FormikField
                name="valability"
                labelText="Valabilitate"
                fieldType="textarea"
              />
              <FormikField
                name="unitOfMeasure"
                labelText="Unitate de masura (ml,g)"
                fieldType="text"
              />
              <UseInstructionsDiv name="useInstructions" values={values} />
              <SpecificationsDiv name="specifications" values={values} />
              <WarningsDiv name="warnings" values={values} />
              <DescriptionsDiv name="descriptions" values={values} />
              <PricesByQntDiv name="pricesByQnt" values={values} />
              <SelectIngredientsDiv
                name="selectedIngredientsIds"
                values={values}
                setFieldValue={setFieldValue}
              />
              <VisibilityDiv
                name="invisibilityCheckBox"
                visibility={visibility}
                setVisibility={setVisibility}
              />

              <button type="submit" className="btn btn-primary mt-2">
                Adauga
              </button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddProduct;
