import { useQuery } from "@tanstack/react-query";
import { Axios } from "../../config";

const useGetCompleteProductById = (id) => {
  const getCompleteProductById = async () => {
    try {
      const { data } = await Axios.get(`products/getCompleteProductById/${id}`);
      console.log("API Response:", data);
      return data;
    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  };
  return useQuery({
    queryKey: ["getCompleteProductById", id],
    queryFn: getCompleteProductById,
  });
};

export default useGetCompleteProductById;
