import React from "react";
import styled from "@emotion/styled";
import FormikField from "../FormikField";
import { FieldArray } from "formik";

const Label = styled.div`
  color: red;
`;
const RemoveBtn = styled.button`
  width: 80px;
  height: 40px;
`;
const PricesByQntDiv = ({ name, values }) => {
  return (
    <div>
      <Label htmlFor={name}>Preturi</Label>
      <FieldArray name={name}>
        {({ push, remove }) => (
          <div className="mb-2">
            {values.pricesByQnt.map((_, index) => (
              <div key={index} className="d-flex mb-1">
                <FormikField
                  name={`pricesByQnt[${index}].quantity`}
                  placeholder="Quantity"
                  fieldType="number"
                  className="form-control"
                />
                <FormikField
                  name={`pricesByQnt[${index}].price`}
                  placeholder="Price"
                  fieldType="number"
                  className="form-control"
                />
                <RemoveBtn
                  type="button"
                  className="btn btn-danger"
                  onClick={() => remove(index)}
                >
                  Remove
                </RemoveBtn>
              </div>
            ))}
            <button
              type="button"
              className="btn btn-info"
              onClick={() => push({ quantity: "", price: "" })}
            >
              Add Price
            </button>
          </div>
        )}
      </FieldArray>
    </div>
  );
};

export default PricesByQntDiv;
