import React from "react";
import styled from "@emotion/styled";
import PreviewImage from "../PreviewImage";

const Label = styled.label`
  color: red;
  margin-right: 10px;
`;

const ImageDiv = ({ name, fileRef, setFieldValue, values }) => {
  return (
    <div className="d-flex align-items-center mb-2">
      <Label>Incarca imagine:</Label>
      <input
        id="image"
        name={name}
        ref={fileRef}
        hidden
        type="file"
        onChange={(e) => {
          setFieldValue("image", e.target.files[0]);
        }}
      />
      {values.image && <PreviewImage file={values.image} />}

      <button
        type="button"
        onClick={() => {
          fileRef.current.click();
        }}
        className="btn btn-outline-info"
      >
        Upload
      </button>
    </div>
  );
};

export default ImageDiv;
