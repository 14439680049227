import React from "react";
import styled from "@emotion/styled";
import { themeSections } from "../../../Data/themeVariables";
import { Link } from "react-router-dom";
import { useActivateThemeMutation } from "../../../myHooks/myMutations/editMutations/editIsActiveMutation";
import { useDeleteTheme } from "../../../myHooks/myMutations/deleteMutations/deleteThemeMutation";

const ThemeWrap = styled.div`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 20px;
`;
const BtnsDiv = styled.div`
  //border: 2px solid red;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
const Btn = styled.button``;
const SectionWrap = styled.div`
  border: 2px dotted blue;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const SingleColorDiv = styled.div`
  border: 1px solid grey;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  gap: 10px;
`;
const ColorFieldName = styled.div`
  color: red;
`;
const ColorsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;
const Color = styled.div`
  width: 60px;
  height: 30px;
`;
const ImagesWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
const SingleImageDiv = styled.div`
  border: 1px solid grey;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const ImageFieldName = styled.div`
  color: red;
`;
const SingleTheme = ({ theme }) => {
  const mutation = useActivateThemeMutation(theme.id);
  const deleteTheme = useDeleteTheme();
  const handleInactiveBtn = (theme) => {
    if (theme.isActive) {
      alert("Tema este deja activa!");
    } else {
      mutation.mutate(theme);
    }
  };
  return (
    <ThemeWrap>
      <BtnsDiv>
        <Btn className="btn " style={{ backgroundColor: " rgba(255,215,0,1)" }}>
          <Link
            to={`/admin/editeaza-tema/${theme.id}`}
            style={{
              textDecoration: "none",
              color: "black",
            }}
          >
            Editează
          </Link>
        </Btn>
        <Btn
          className="btn "
          style={{
            backgroundColor: "rgba(184,15,10,1)",
            color: "white",
          }}
          onClick={() => deleteTheme(theme.id)}
        >
          Sterge
        </Btn>
        <Btn
          className="btn "
          style={{
            backgroundColor: theme.isActive ? "green" : "grey",
            color: "white",
          }}
          onClick={() => handleInactiveBtn(theme)}
        >
          {theme.isActive ? "Activa" : "Inactiva"}
        </Btn>
      </BtnsDiv>
      <h5 style={{ color: "blue" }} className="mt-4">
        {theme.themeName}
      </h5>
      {themeSections.map((section) => {
        return (
          <SectionWrap key={section.id}>
            <h6 style={{ color: "blue" }}>{section.name}</h6>
            <ColorsWrap>
              {section.colorFields.map((color) => {
                return (
                  <SingleColorDiv key={color.id}>
                    <ColorFieldName>{color.colorFieldName}</ColorFieldName>
                    <Color
                      style={{ backgroundColor: theme[color.colorFieldName] }}
                    />
                  </SingleColorDiv>
                );
              })}
            </ColorsWrap>
            <ImagesWrap>
              {section.sectionImages.map((image) => {
                return (
                  <SingleImageDiv key={image.id}>
                    {theme[image.imageFieldName] && (
                      <img
                        src={theme[image.imageFieldName]}
                        key={image.id}
                        alt="theme image"
                        style={{ width: "100px" }}
                      />
                    )}
                    <ImageFieldName>{image.imageFieldName}</ImageFieldName>
                  </SingleImageDiv>
                );
              })}
            </ImagesWrap>
          </SectionWrap>
        );
      })}
    </ThemeWrap>
  );
};

export default SingleTheme;
