import { useQueryClient } from "@tanstack/react-query";
import { Axios } from "../../../config";

export const useDeleteCategory = () => {
  const queryClient = useQueryClient();

  const deleteCategory = async (id) => {
    await queryClient.cancelQueries(["getListOfCategories"]);
    const previousCategories = queryClient.getQueryData([
      "getListOfCategories",
    ]);
    queryClient.setQueryData(["getListOfCategories"], (oldData) =>
      oldData.filter((category) => category.id !== id)
    );

    try {
      await Axios.delete(`categories/${id}`);
    } catch (error) {
      queryClient.setQueryData(["getListOfCategories"], previousCategories);
      console.error("Eroare la ștergerea categoriei:", error);
    }
  };
  return deleteCategory;
};
