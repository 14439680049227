import * as Yup from "yup";

export const editIngredientValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Numele este obligatoriu")
    .min(2, "Numele trebuie să aibă cel puțin 3 caractere")
    .max(100, "Numele nu poate depăși 100 de caractere"),
  description: Yup.string()
    .required("Descrierea este obligatorie")
    .min(1, "Descrierea trebuie să aibă cel puțin 10 caractere")
    .max(1000, "Descrierea nu poate depăși 1000 de caractere"),
});
