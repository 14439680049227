import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Axios } from "../../../config";

export const useActivateThemeMutation = (id) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      console.log("id:", id);

      await Axios.patch(`themes/activateTheme/${id}`);
    },

    onMutate: async (id) => {
      // Înregistrăm temele anterioare pentru a le putea restaura în caz de eroare
      const previousThemes = queryClient.getQueryData(["getListOfThemes"]);
      queryClient.setQueryData(["getListOfThemes"], (oldData) =>
        oldData.map((theme) =>
          theme.id === id
            ? { ...theme, isActive: true }
            : { ...theme, isActive: false }
        )
      );
      return { previousThemes };
    },
    onError: (error, id, context) => {
      // Restaurăm datele anterioare în caz de eroare
      queryClient.setQueryData(["getListOfThemes"], context.previousThemes);
      console.error("Eroare la activarea temei:", error);
    },
    onSuccess: () => {
      // Invalidează și refacă lista de teme pentru a o actualiza
      queryClient.invalidateQueries(["getListOfThemes"]);
    },
  });
};
