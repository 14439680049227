import React, { useEffect, useState } from "react";

const PreviewImage = ({ file }) => {
  const [preview, setPreview] = useState("");

  useEffect(() => {
    if (!file) return;
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(file);
    return () => {
      setPreview("");
    };
  }, [file]);
  if (!preview) {
    return <div>Loading...</div>;
  }
  return (
    <div className="p-2">
      <img
        src={preview}
        alt="Preview"
        style={{ width: "100px", border: "1px solid grey" }}
      />
    </div>
  );
};
export default PreviewImage;
