import React from "react";
import Select from "react-select";
import styled from "@emotion/styled";
import { handleSubcategoryChange } from "../../myFunctions";
const MyLabel = styled.label`
  color: red;
`;

const SubcategorySelectList = ({
  menuItem,
  name,
  category,
  setFieldValue,
  values,
  categoryIndex,
  menuItemIndex,
  subcategoriesData,
}) => {
  return (
    <div>
      <MyLabel htmlFor={name}>
        Subcategorii pentru {`${category.categoryObj.name}`}
      </MyLabel>
      <Select
        isMulti
        options={category.filteredSubcategoriesObj}
        value={menuItem.categories[categoryIndex].subcategories.map(
          (subcat) => {
            return {
              value: subcat.id,
              label: subcat.name,
            };
          }
        )}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={(selected) => {
          handleSubcategoryChange(
            selected,
            setFieldValue,
            values,
            categoryIndex,
            menuItemIndex,
            subcategoriesData
          );
        }}
      />
    </div>
  );
};

export default SubcategorySelectList;
