import React, { use, useEffect, useState } from "react";
import MenuItemSelectList from "../../Components/AddSubcategory/MenuItemSelectList";
import { Form, Formik } from "formik";
import CategoriesSelectList from "../../Components/AddSubcategory/CategoriesSelectList";
import FormikField from "../../Components/FormikField";
import { addSubcategoryInitialValues } from "../../initialValuesFormik/subcategory/addSubcategoryInitialValues";
import { addSubcategoryValidationSchema } from "../../validationSchemas/subcategory/addSubcategoryValidationSchema";
import { useAddSubcategory } from "../../../myHooks/myMutations/addMutations/addSubcategoryMutation";

const AddSubcategory = () => {
  const mutation = useAddSubcategory();
  const onSubmit = (data) => {
    mutation.mutate(data);
  };
  return (
    <div className="d-flex flex-column align-items-center justify-content-center w-100 ">
      <h5>Adauga subcategorie</h5>
      <Formik
        initialValues={addSubcategoryInitialValues}
        validationSchema={addSubcategoryValidationSchema}
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values }) => {
          return (
            <Form style={{ width: "60%", padding: "20px" }}>
              <MenuItemSelectList
                name="selectedMenuItemId"
                value={values.selectedMenuItemOption}
                onMenuItemChange={(selectedOption) => {
                  setFieldValue("selectedMenuItemId", selectedOption.value);
                  setFieldValue("selectedMenuItemOption", selectedOption);
                }}
              />
              {values.selectedMenuItemId && (
                <CategoriesSelectList
                  selectedMenuItemId={values.selectedMenuItemId}
                  name="selectedCategoryId"
                  onCategoryChange={(selectedOption) => {
                    setFieldValue("selectedCategoryId", selectedOption.value);
                    setFieldValue("selectedCategoryOption", selectedOption);
                  }}
                />
              )}
              <FormikField
                name="name"
                labelText="Nume subcategorie"
                fieldType="text"
              />

              <button type="submit" className="btn btn-primary mt-2">
                Adauga
              </button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddSubcategory;
