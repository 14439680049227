import React, { createContext, useContext } from "react";
import useGetCategoriesData from "../myHooks/category/useGetCategoriesData";

// Creează contextul
const CategoriesDataContext = createContext();

// Creează un custom hook pentru a folosi contextul
export const useCategoriesData = () => {
  return useContext(CategoriesDataContext);
};

// Creează providerul pentru context
export const CategoriesDataProvider = ({ children }) => {
  const {
    data: categoriesData,
    isLoading: isLoadingCategoriesData,
    isError: isErrorCategoriesData,
    error: errorCategoriesData,
  } = useGetCategoriesData();

  return (
    <CategoriesDataContext.Provider
      value={{
        data: categoriesData,
        isLoading: isLoadingCategoriesData,
        isError: isErrorCategoriesData,
        error: errorCategoriesData,
      }}
    >
      {children}
    </CategoriesDataContext.Provider>
  );
};
