import React, { useState } from "react";
import styled from "@emotion/styled";

const HeaderWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 700px) {
  }
`;
const ProductImage = styled.img`
  border-radius: 10px;
  flex-shrink: 0;
  width: 200px;
  height: 266px;
  @media screen and (max-width: 700px) {
    width: 150px;
    height: 200px;
  }
`;
const Title = styled.div`
  //border: 4px solid blue;
  padding: 10px 20px;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  @media screen and (max-width: 700px) {
    font-size: 18px;
  }
`;
const ImageAndPriceAndValability = styled.div`
  //border: 4px solid blue;
  display: flex;
  align-items: center;
`;
const PriceAndValability = styled.div`
  // border: 4px solid red;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 700px) {
    padding: 0 20px;
  }
`;
const PriceAndQuantitiesOptions = styled.div`
  // /border: 4px solid red;
`;
const Price = styled.div`
  //border: 4px solid blue;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CurrentPrice = styled.div`
  font-weight: 600;
  font-size: 18px;

  @media screen and (max-width: 400px) {
    font-size: 17px;
  }
`;

const Valability = styled.div`
  padding: 5px 10px;
  margin-top: 10px;
`;
const ValabilityTitle = styled.span`
  font-weight: 600;
  color: rgb(210, 4, 45);
`;

//-------------QUANTITIES OPTIONS-------------
const QuantitiesOptions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const OptionsDiv = styled.div`
  display: flex;
  justify-content: start;
  font-size: 16px;
`;

const Header = ({ product, currentTheme }) => {
  const [isQuantitySelected, setIsQuantitySelected] = useState(
    product.priceByQnts[0].id
  );
  const [currentPrice, setCurrentPrice] = useState(
    product.priceByQnts[0].price
  );

  console.log("product", product);
  const handleQuantityChange = (item) => {
    console.log("item", item);
    setIsQuantitySelected(item.id);
    setCurrentPrice(item.price);
  };
  return (
    <HeaderWrap>
      <Title>{product.name}</Title>
      <ImageAndPriceAndValability>
        <ProductImage
          src={product.productImage ? product.productImage.src : null}
          alt="product-image-product-page"
        />

        <PriceAndValability>
          <PriceAndQuantitiesOptions>
            <Price>
              <CurrentPrice
                style={{
                  color: currentTheme
                    ? `${currentTheme.productPagePriceColor}`
                    : "black",
                }}
              >
                <span style={{ marginRight: "1px" }}>{currentPrice}</span>
                Ron
              </CurrentPrice>
            </Price>
            <QuantitiesOptions>
              <div style={{ marginBottom: "5px" }}>Ambalaj</div>
              <OptionsDiv>
                {product.priceByQnts.map((item) => {
                  return (
                    <div
                      onClick={() => {
                        handleQuantityChange(item);
                      }}
                      key={item.id}
                      className="d-flex"
                      style={{
                        color: currentTheme
                          ? `${currentTheme.productPageTextColor}`
                          : "black",
                        marginRight: "8px",
                        border:
                          isQuantitySelected === item.id
                            ? "1px solid rgb(210, 4, 45)"
                            : "1px solid rgb(169, 169, 169) ",
                      }}
                    >
                      <div
                        style={{
                          padding: "3px 5px",
                          cursor: "pointer",
                        }}
                      >
                        {item.quantity}
                        {product.unitOfMeasure}
                      </div>
                    </div>
                  );
                })}
              </OptionsDiv>
            </QuantitiesOptions>
          </PriceAndQuantitiesOptions>
          <Valability>
            <ValabilityTitle
              style={{
                color: currentTheme
                  ? currentTheme.productPageValabilityColor
                  : "rgb(210, 4, 45)",
              }}
            >
              Valabilitate:{" "}
            </ValabilityTitle>
            {product.valability}
          </Valability>
        </PriceAndValability>
      </ImageAndPriceAndValability>
    </HeaderWrap>
  );
};

export default Header;
