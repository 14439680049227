export const themeSections = [
  {
    id: 1,
    name: "HOME PAGE SI HEADER",
    sectionImages: [
      {
        id: 1,
        imageFieldName: "homePageImgSrc",
        label: "Selecteaza imagine pentru home page",
      },
      {
        id: 2,
        imageFieldName: "homePageLucilleNaturalCareImgSrc",
        label: "Selecteaza imagine pentru lucille natural care",
      },
      {
        id: 3,
        imageFieldName: "homePageCineSuntEuImgSrc",
        label: "Selecteaza imagine pentru cine sunt eu",
      },
      {
        id: 4,
        imageFieldName: "headerCentralImgSrc",
        label: "Selecteaza imagine centrala pentru header",
      },
      {
        id: 5,
        imageFieldName: "headerLeftImageSrc",
        label: "Selecteaza imagine stanga header",
      },
      {
        id: 6,
        imageFieldName: "headerRightImageSrc",
        label: "Selecteaza imagine dreapta pentru header",
      },
    ],
    colorFields: [
      {
        id: 1,
        colorFieldName: "homePageCineSuntEuBackgroundColor",
        label: "Background color Cine sunt eu",
      },
      {
        id: 2,
        colorFieldName: "homePageSoapsSectionBackgroundColor",
        label: "Background color Soaps Section",
      },
      {
        id: 3,
        colorFieldName: "headerColor",
        label: "Header Color",
      },
      {
        id: 4,
        colorFieldName: "headerTextColor",
        label: "Header Text Color",
      },
    ],
  },

  {
    id: 2,
    name: "PRODUCT LIST PAGE",
    sectionImages: [
      {
        id: 1,
        imageFieldName: "productListPageBackgroundImageSrc",
        label: "Selecteaza imagine pentru background product list",
      },
      {
        id: 2,
        imageFieldName: "productListPageSelectListIconSrc",
        label: "Selecteaza imagine pentru icon select list",
      },
    ],
    colorFields: [
      {
        id: 1,
        colorFieldName: "productListPageBackgroundMaskColor",
        label: "Background mask color",
      },
      {
        id: 2,
        colorFieldName: "productsContainerBorder",
        label: "Products container border",
      },
      {
        id: 3,
        colorFieldName: "productsContainerBackgroundColor",
        label: "Products container background color",
      },
      {
        id: 4,
        colorFieldName: "cardBackgroundColor",
        label: "Card background color",
      },
      {
        id: 5,
        colorFieldName: "cardTitleColor",
        label: "Card title color",
      },
      {
        id: 6,
        colorFieldName: "cardSpecificationsColor",
        label: "Card specifications color",
      },
      {
        id: 7,
        colorFieldName: "cardPriceColor",
        label: "Card price color",
      },
      {
        id: 8,
        colorFieldName: "cardBtnBackgroundColor",
        label: "Card button background color",
      },
      {
        id: 9,
        colorFieldName: "cardBtnTextColor",
        label: "Card button text color",
      },
    ],
  },
  {
    id: 3,
    name: "PRODUCT PAGE",
    sectionImages: [
      {
        id: 1,
        imageFieldName: "productPageBackgroundImgSrc",
        label: "Selecteaza imagine pentru background product page",
      },
    ],
    colorFields: [
      {
        id: 1,
        colorFieldName: "productPageBackgroundMaskColor",
        label: "Background mask color",
      },
      {
        id: 2,
        colorFieldName: "productContainerBorder",
        label: "Product container border",
      },
      {
        id: 3,
        colorFieldName: "productContainerBackgroundColor",
        label: "Product container background color",
      },
      {
        id: 4,
        colorFieldName: "productPageTitleColor",
        label: "Product page title color",
      },
      {
        id: 5,
        colorFieldName: "productPagePriceColor",
        label: "Product page price color",
      },
      {
        id: 6,
        colorFieldName: "productPageValabilityColor",
        label: "Product page valability color",
      },
      {
        id: 7,
        colorFieldName: "productPageTextColor",
        label: "Product page text color",
      },
    ],
  },
  {
    id: 4,
    name: "MOBILE",
    sectionImages: [
      {
        id: 1,
        imageFieldName: "mobileHeaderLeftImgSrc",
        label: "Selecteaza imagine stanga pentru mobile header",
      },
      {
        id: 2,
        imageFieldName: "mobileHeaderRightImgSrc",
        label: "Selecteaza imagine dreapta pentru mobile header",
      },
      {
        id: 3,
        imageFieldName: "sideNavMenuItemIconSrc",
        label: "Selecteaza imagine pentru icon side nav menu item",
      },
    ],
    colorFields: [
      {
        id: 1,
        colorFieldName: "startLinearGradientLucilles",
        label: "Start linear gradient lucilles",
      },
      {
        id: 2,
        colorFieldName: "endLinearGradientLucilles",
        label: "End linear gradient lucilles",
      },
      {
        id: 3,
        colorFieldName: "hambourgerColor",
        label: "Hambourger color",
      },
      {
        id: 4,
        colorFieldName: "sideNavMenuItemColor",
        label: "Side nav menu item color",
      },
      {
        id: 5,
        colorFieldName: "dropdownDivColor",
        label: "Dropdown div color",
      },
      {
        id: 6,
        colorFieldName: "dropdownDivTextColor",
        label: "Dropdown div text color",
      },
      {
        id: 7,
        colorFieldName: "backgroundCloseIcon",
        label: "Background close icon",
      },
      {
        id: 8,
        colorFieldName: "closeIconXColor",
        label: "Close icon x color",
      },
    ],
  },
];
//fara isActive
export const allFieldNames = [
  "themeName",
  "homePageImgSrc",
  "homePageLucilleNaturalCareImgSrc",
  "homePageCineSuntEuBackgroundColor",
  "homePageCineSuntEuImgSrc",
  "homePageSoapsSectionBackgroundColor",
  "headerColor",
  "headerTextColor",
  "headerCentralImgSrc",
  "headerLeftImageSrc",
  "headerRightImageSrc",
  "productListPageBackgroundImageSrc",
  "productListPageBackgroundMaskColor",
  "productsContainerBorder",
  "productsContainerBackgroundColor",
  "cardBackgroundColor",
  "cardTitleColor",
  "cardSpecificationsColor",
  "cardPriceColor",
  "cardBtnBackgroundColor",
  "cardBtnTextColor",
  "productListPageSelectListIconSrc",
  "productPageBackgroundImgSrc",
  "productPageBackgroundMaskColor",
  "productContainerBorder",
  "productContainerBackgroundColor",
  "productPageTitleColor",
  "productPagePriceColor",
  "productPageValabilityColor",
  "productPageTextColor",
  "mobileHeaderLeftImgSrc",
  "mobileHeaderRightImgSrc",
  "startLinearGradientLucilles",
  "endLinearGradientLucilles",
  "sideNavMenuItemIconSrc",
  "hambourgerColor",
  "sideNavMenuItemColor",
  "dropdownDivColor",
  "dropdownDivTextColor",
  "backgroundCloseIcon",
  "closeIconXColor",
  
];
