import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { useMenuItemsData } from "../../../../Contexts/MenuItemsDataContext";

import First3MenuItems from "./First3MenuItems";
import MiddleImage from "./MiddleImage";
import Last3MenuItems from "./Last3MenuItems";
import LeftImage from "./LeftImage";
import RightImage from "./RightImage";
const appear = keyframes`
from{
  opacity:0;}
  to{
    opacity:1;}
  
  `;

const DesktopNav = styled.div`
  height: 90px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0px;
  z-index: 100;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  animation: ${appear} 0.7s ease forwards;

  @media (max-width: 1000px) {
    display: none;
  }
`;
const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 90%;
  height: 100%;
  background-color: transparent;
  position: relative;
`;
const DesktopNavbar = ({ currentTheme }) => {
  const { data: menuItems } = useMenuItemsData();

  return (
    <>
      {menuItems && (
        <DesktopNav
          style={{
            backgroundColor: currentTheme
              ? `${currentTheme.headerColor}`
              : " rgb(40, 110, 160)",
          }}
        >
          <LeftImage currentTheme={currentTheme} />
          <Menu>
            <First3MenuItems
              menuItems={menuItems}
              currentTheme={currentTheme}
            />

            <MiddleImage currentTheme={currentTheme} />

            <Last3MenuItems menuItems={menuItems} currentTheme={currentTheme} />
          </Menu>
          <RightImage currentTheme={currentTheme} />
        </DesktopNav>
      )}
    </>
  );
};

export default DesktopNavbar;
