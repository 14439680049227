import React from "react";
import { Formik, Form } from "formik";
import { addIngredientInitialValues } from "../../initialValuesFormik/ingredient/addIngredientInitialValues";
import { addIngredientValidationSchema } from "../../validationSchemas/ingredient/addIngredientValidationSchema";
import { useAddIngredient } from "../../../myHooks/myMutations/addMutations/addIngredientMutation";
import FormikField from "../../Components/FormikField";

const AddIngredient = () => {
  const mutation = useAddIngredient();
  const onSubmit = (data) => {
    mutation.mutate(data);
  };
  return (
    <div className="w-100 d-flex align-items-center justify-content-center">
      <div style={{ width: "80%" }}>
        <h5 className="text-center">Adauga ingredient:</h5>
        <Formik
          initialValues={addIngredientInitialValues}
          validationSchema={addIngredientValidationSchema}
          onSubmit={onSubmit}
        >
          <Form>
            <FormikField
              name="name"
              labelText="Nume"
              fieldType="text"
              fieldStyle={{ width: "100%" }}
            />
            <FormikField
              name="description"
              labelText="Descriere"
              fieldType="textarea"
              fieldStyle={{
                width: "100%",
                height: "150px",
                resize: "vertical",
                overflowY: "scroll",
              }}
            />
            <button
              type="submit"
              className="btn btn-primary mt-2"
              disabled={mutation.isLoading}
            >
              {mutation.isLoading ? "Se incarcă..." : "Adauga"}
              Adauga
            </button>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default AddIngredient;
