import React from "react";
import styled from "@emotion/styled";

const SelectedImagesSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;
const SelectedImage = styled.div`
  border: 1px solid grey;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const ImagesSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: end;
  gap: 10px;
`;
const DisplaySelectedThemeImagesSection = ({ selectedImages, section }) => {
  return (
    <SelectedImagesSection>
      <h6 style={{ color: "blue" }}>Imagini selectate :</h6>
      <ImagesSection>
        {selectedImages
          .filter((item) => {
            return section.sectionImages
              .map((el) => {
                return el.imageFieldName;
              })
              .includes(item.fieldName);
          })
          .map((image) => {
            return (
              <SelectedImage key={image.fieldName}>
                {image.src ? (
                  <img
                    src={image.src}
                    alt="selected image"
                    style={{ width: "100px" }}
                  />
                ) : null}
                <div style={{ color: "red" }}>{image.fieldName}</div>
              </SelectedImage>
            );
          })}
      </ImagesSection>
    </SelectedImagesSection>
  );
};

export default DisplaySelectedThemeImagesSection;
