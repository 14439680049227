import React from "react";
import { useThemeImagesData } from "../../../Contexts/ThemeImagesDataContext";
import styled from "@emotion/styled";

const ThemeImagesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: end;
  gap: 15px;
`;
const ThemeImage = styled.img`
  width: 100px;
  border: 1px solid grey;
`;
const ThemeImagesList = ({ onSelect }) => {
  const { data: themeImagesData } = useThemeImagesData();
  return (
    <ThemeImagesContainer>
      {themeImagesData &&
        themeImagesData.map((image) => {
          return (
            <ThemeImage
              src={image.src}
              key={image.id}
              alt="theme image"
              onClick={() => onSelect(image)}
            />
          );
        })}
    </ThemeImagesContainer>
  );
};

export default ThemeImagesList;
