import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { getIngredientById } from "../../myFunctions";
import { useIngredientsData } from "../../../Contexts/IngredientsDataContext";
import { useEditIngredientMutation } from "../../../myHooks/myMutations/editMutations/editIngredientMutation";
import { editIngredientInitialValues } from "../../initialValuesFormik/ingredient/editIngredientInitialValues";
import FormikField from "../../Components/FormikField";
import { editIngredientValidationSchema } from "../../validationSchemas/ingredient/editIngredientValidationSchema";

const EditIngredient = () => {
  const [currentIngredient, setCurrentIngredient] = useState();
  const [initialValues, setInitialValues] = useState({});
  const { id } = useParams();
  const { data: ingredientsData } = useIngredientsData();

  useEffect(() => {
    if (ingredientsData) {
      const currentIngredient = getIngredientById(id, ingredientsData);
      if (currentIngredient) {
        editIngredientInitialValues(currentIngredient, setInitialValues);
      }
      setCurrentIngredient(currentIngredient);
    }
  }, [ingredientsData, id]);

  const mutation = useEditIngredientMutation(id);

  const onSubmit = (data) => {
    mutation.mutate(data);
  };
  return (
    <div className="w-100 d-flex align-items-center justify-content-center">
      <div style={{ width: "80%" }}>
        <h5 className="text-center">Editează ingredient:</h5>
        {currentIngredient && (
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            enableReinitialize={true}
            validationSchema={editIngredientValidationSchema}
          >
            <Form>
              <FormikField
                name="name"
                labelText="Nume"
                fieldType="text"
                placeholder="nume"
                fieldStyle={{ width: "100%" }}
              />
              <FormikField
                name={"description"}
                labelText={"Descriere"}
                fieldType={"textarea"}
                rows={5}
                placeholder={"descriere"}
                fieldStyle={{
                  width: "100%",
                  height: "150px",
                  resize: "vertical",
                  overflowY: "scroll",
                }}
              />
              <button
                type="submit"
                className="btn btn-primary mt-2"
                disabled={mutation.isLoading}
              >
                {mutation.isLoading ? "Se modifica..." : "Editeaza"}
              </button>
            </Form>
          </Formik>
        )}
      </div>
    </div>
  );
};

export default EditIngredient;
