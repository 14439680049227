import React from "react";
import { Formik, Form } from "formik";
import FormikField from "../../Components/FormikField";
import MenuItemsSelectList from "../../Components/AddCategory/MenuItemSelectList";
import { useAddCategory } from "../../../myHooks/myMutations/addMutations/addCategoryMutation";
import { addCategoryValidationSchema } from "../../validationSchemas/category/addCategoryValidationSchema";
import { addCategoryInitialValues } from "../../initialValuesFormik/category/addCategoryInitialValues";

const AddCategory = () => {
  const mutation = useAddCategory();

  const onSubmit = (data) => {
    const newCategory = {
      name: data.name,
      path: data.path,
      menuItemId: data.selectedMenuItemId,
    };
    mutation.mutate(newCategory);
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center w-100 ">
      <h5>Adauga categorie</h5>

      <Formik
        initialValues={addCategoryInitialValues}
        validationSchema={addCategoryValidationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form style={{ width: "60%" }}>
            <FormikField name="name" labelText="Nume" fieldType="text" />
            <FormikField name="path" labelText="Path" fieldType="text" />

            <MenuItemsSelectList
              name="selectedMenuItem"
              value={values.selectedMenuItemOption}
              onMenuItemChange={(selectedOption) => {
                setFieldValue("selectedMenuItemId", selectedOption.value);
                setFieldValue("selectedMenuItemOption", selectedOption);
              }}
            />
            <button type="submit" className="btn btn-primary">
              Adauga
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddCategory;
