import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Axios } from "../../../config";

export const useEditCategoryMutation = (id) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: async (updatedCategory) =>
      await Axios.patch(`categories/${id}`, updatedCategory).then((res) => {
        console.log(res.data);
      }),
    onMutate: (updatedCategory) => {
      const previousCategories = queryClient.getQueryData([
        "getListOfCategories",
      ]);
      queryClient.setQueryData(["getListOfCategories"], (oldData = []) => {
        return oldData.map((category) =>
          category.id === id ? { ...updatedCategory, id: id } : category
        );
      });
      return { previousCategories };
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["getListOfCategories"]);
      navigate("/admin/lista-categorii");
    },
    onError: (error, context) => {
      queryClient.setQueryData(
        ["getListOfCategories"],
        context.previousCategories
      );
      console.error("Eroare la editarea categoriei:", error);
    },
  });
};
