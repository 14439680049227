import React from "react";
import styled from "@emotion/styled";

const MyButtonsSection = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const SelectImgBtn = styled.button``;

const ButtonsSection = ({
  section,
  setShowThemeImages,
  isActiveBtn,
  setIsActiveBtn,
}) => {
  return (
    <MyButtonsSection>
      {section.sectionImages.map((sectionImage) => {
        return (
          <SelectImgBtn
            type="button"
            key={sectionImage.id}
            className="btn btn-primary"
            onClick={() => {
              setShowThemeImages(sectionImage.imageFieldName);
              setIsActiveBtn(sectionImage.imageFieldName);
            }}
            style={{
              backgroundColor:
                isActiveBtn === sectionImage.imageFieldName ? "red" : "green",
              color: "white",
            }}
          >
            {sectionImage.imageFieldName}
          </SelectImgBtn>
        );
      })}
    </MyButtonsSection>
  );
};

export default ButtonsSection;
