import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Axios } from "../../../config";

export const useAddTheme = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: async (data) => {
      await Axios.post("themes", data).then((res) => {
        console.log("res:", res.data);
      });
    },
    onMutate: async (newTheme) => {
      const previousThemes = queryClient.getQueryData(["getListOfThemes"]);
      queryClient.setQueryData(["getListOfThemes"], (oldData) => [
        ...(oldData || []),
        { id: Date.now(), ...newTheme },
      ]);
      return { previousThemes };
    },
    onError: (error, data, context) => {
      queryClient.setQueryData(["getListOfThemes"], context.previousThemes);
      console.error("Eroare la adăugarea temei:", error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["getListOfThemes"]);
      navigate("/admin/lista-teme");
    },
  });
};
