import React from "react";
import styled from "@emotion/styled";

const Warnings = styled.ul``;
const WarningsTitle = styled.div``;
const WarningsWrap = styled.div``;
const Warning = styled.li``;

const WarningsDiv = ({ product, currentTheme }) => {
  return (
    <>
      {product.warnings && product.warnings.length > 0 && (
        <WarningsWrap>
          <WarningsTitle
            style={{
              color: currentTheme
                ? currentTheme.productPageValabilityColor
                : "rgb(210, 4, 45)",

              fontWeight: "600",
            }}
          >
            Atenționări:{" "}
          </WarningsTitle>
          <Warnings>
            {product.warnings.map((warning) => {
              return <Warning>{warning.warning}</Warning>;
            })}
          </Warnings>
        </WarningsWrap>
      )}
    </>
  );
};

export default WarningsDiv;
