import React from "react";
import styled from "@emotion/styled";
import CurrentThemeImagesSection from "./CurrentThemeImagesSection";
import ButtonsSection from "./ButtonsSection";
import DisplayThemeImagesForSelection from "./DisplayThemeImagesForSelection";
import DisplaySelectedThemeImagesSection from "./DisplaySelectedThemeImagesSection";

const ImagesSection = styled.div`
  //border: 2px solid green;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 10px 0 10px 0;
`;

const SelectThemeImagesSection = ({
  section,
  setShowThemeImages,
  isActiveBtn,
  setIsActiveBtn,
  showThemeImages,
  handleImageSelect,
  setFieldValue,
  selectedImages,
  currentTheme,
  page,
}) => {
  return (
    <ImagesSection>
      {page === "EditTheme" && (
        <CurrentThemeImagesSection
          currentTheme={currentTheme}
          section={section}
        />
      )}
      <ButtonsSection
        section={section}
        setShowThemeImages={setShowThemeImages}
        isActiveBtn={isActiveBtn}
        setIsActiveBtn={setIsActiveBtn}
      />
      {section.sectionImages.map((image) => {
        return (
          <div key={image.id}>
            {showThemeImages === image.imageFieldName && (
              <DisplayThemeImagesForSelection
                section={section}
                showThemeImages={showThemeImages}
                handleImageSelect={handleImageSelect}
                setFieldValue={setFieldValue}
              />
            )}
          </div>
        );
      })}

      <DisplaySelectedThemeImagesSection
        selectedImages={selectedImages}
        section={section}
      />
    </ImagesSection>
  );
};

export default SelectThemeImagesSection;
