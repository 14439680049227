import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

const SideNavLink = styled(Link)`
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  text-decoration: none;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
`;

const NoDropdownMenuItem = ({
  menuItem,
  currentTheme,
  sideNav,
  setSideNav,
}) => {
  return (
    <SideNavLink
      key={menuItem.id}
      to={menuItem.path}
      style={{
        color: currentTheme ? `${currentTheme.sideNavMenuItemColor}` : "white",
      }}
      onClick={() => {
        setSideNav(!sideNav);
      }}
    >
      <div className="d-flex align-items-center">
        <img
          src={currentTheme ? `${currentTheme.sideNavMenuItemIconSrc}` : null}
          style={{ marginRight: "5px", width: "15px" }}
        />
        {menuItem.name}
      </div>
    </SideNavLink>
  );
};

export default NoDropdownMenuItem;
