import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import ImageDiv from "../../Components/EditProduct/ImageDiv";
import SelectListsDiv from "../../Components/EditProduct/SelectListsDiv";
import FormikField from "../../Components/FormikField";
import UseInstructionsDiv from "../../Components/AddProduct/UseInstructionsDiv";
import SpecificationsDiv from "../../Components/AddProduct/SpecificationsDiv";
import WarningsDiv from "../../Components/AddProduct/WarningsDiv";
import DescriptionsDiv from "../../Components/AddProduct/DescriptionsDiv";
import PricesByQntDiv from "../../Components/AddProduct/PricesByQntDiv";
import SelectIngredientsDiv from "../../Components/EditProduct/SelectIngredientsDiv";
import { editProductInitialValues } from "../../initialValuesFormik/product/editProductInitialValues";
import useGetCompleteProductById from "../../../myHooks/product/useGetCompleteProductById";
import { useCategoriesData } from "../../../Contexts/CategoriesDataContext";
import { useSubcategoriesData } from "../../../Contexts/SubcategoriesDataContext";
import { useEditProduct } from "../../../myHooks/myMutations/editMutations/editProductMutation";

const EditProduct = () => {
  const params = useParams();
  const id = params.id;
  const fileRef = useRef(null);

  const { data: completeProduct } = useGetCompleteProductById(id);
  // console.log("completeProduct:", completeProduct);
  const { data: categoriesData } = useCategoriesData();
  const { data: subcategoriesData } = useSubcategoriesData();
  const mutation = useEditProduct(id);
  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    if (completeProduct && categoriesData && subcategoriesData) {
      setInitialValues(
        editProductInitialValues(
          completeProduct,
          categoriesData,
          subcategoriesData
        )
      );
    }
  }, [completeProduct, categoriesData, subcategoriesData]);

  const onSubmit = (data) => {
    console.log("data:", data);
    mutation.mutate(data);
  };
  return (
    <>
      {completeProduct && initialValues && (
        <div className="d-flex  flex-column align-items-center w-100">
          <h5>Editeaza produs :</h5>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {({ values, setFieldValue }) => {
              //console.log("values:", values);
              return (
                <Form style={{ width: "80%" }}>
                  <SelectListsDiv
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                  <FormikField
                    name="name"
                    labelText="Nume produs"
                    fieldType="textarea"
                    rows={1}
                  />
                  <ImageDiv
                    name="image"
                    fileRef={fileRef}
                    setFieldValue={setFieldValue}
                    values={values}
                    completeProduct={completeProduct}
                  />
                  <FormikField
                    name="recipe"
                    labelText="Reteta"
                    fieldType="textarea"
                    rows={6}
                  />
                  <FormikField
                    name="valability"
                    labelText="Valabilitate"
                    fieldType="textarea"
                  />
                  <FormikField
                    name="unitOfMeasure"
                    labelText="Unitate de masura (ml,g)"
                    fieldType="text"
                  />
                  <UseInstructionsDiv name="useInstructions" values={values} />
                  <SpecificationsDiv name="specifications" values={values} />
                  <WarningsDiv name="warnings" values={values} />
                  <DescriptionsDiv name="descriptions" values={values} />
                  <PricesByQntDiv name="pricesByQnt" values={values} />
                  <SelectIngredientsDiv
                    name="selectedIngredientsIds"
                    values={values}
                    setFieldValue={setFieldValue}
                    value={values.selectedIngredientsOptions}
                  />

                  <button type="submit" className="btn btn-primary mt-2">
                    Editeaza
                  </button>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </>
  );
};

export default EditProduct;
