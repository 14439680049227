import React from "react";
import { useProductContainerImagesData } from "../../../Contexts/ProductContainerImagesDataContext";
import styled from "@emotion/styled";

const ProductContainerImagesWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: end;
  gap: 15px;
`;
const ProductContainerImage = styled.img`
  width: 100px;
  border: 1px solid grey;
`;
const ListOfProductContainerImages = ({ onSelect }) => {
  const { data: productContainerImagesData } = useProductContainerImagesData();
  return (
    <ProductContainerImagesWrap>
      {productContainerImagesData &&
        productContainerImagesData.map((image) => {
          return (
            <ProductContainerImage
              src={image.src}
              key={image.id}
              alt="product container image"
              //onClick={() => onSelect(image)}
            />
          );
        })}
    </ProductContainerImagesWrap>
  );
};

export default ListOfProductContainerImages;
