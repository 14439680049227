import React from "react";
import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";

const SpecificationsBody = styled.div`
  width: 100%;
  margin-top: 20px;
  border-radius: 10px;
  font-size: 16px;
  line-height: 20px;
`;
const SingleSpecification = styled.div`
  // /border: 2px solid black;
  display: flex;
  align-items: center;
  padding: 5px 10px;

  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`;
const SpecificationTitle = styled.div`
  // border: 2px solid black;
  width: 200px;
  font-weight: 600;
  flex-shrink: 0;
  color: red;
  @media screen and (max-width: 700px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
const SpecificationDescription = styled.div`
  flex-grow: 1;
  //border: 4px solid green;
  padding: 2px 10px;
`;

const SpecificationsDiv = ({ product, currentTheme }) => {
  return (
    <SpecificationsBody>
      {product.productSpecifications.map((specification, index) => {
        return (
          <SingleSpecification
            key={specification.id}
            style={{
              backgroundColor:
                index % 2 === 0
                  ? currentTheme
                    ? currentTheme.headerColor
                    : "rgb(40, 110, 160)"
                  : "rgba(255,255,255,0.8)",

              color:
                index % 2 === 0
                  ? currentTheme
                    ? currentTheme.headerTextColor
                    : "white"
                  : currentTheme
                  ? currentTheme.productPageTextColor
                  : "rgb(210, 4, 45)",
              borderRadius:
                index === 0
                  ? "10px 10px 0 0"
                  : index === product.productSpecifications.length - 1
                  ? "0 0 10px 10px"
                  : "",
            }}
          >
            <SpecificationTitle
              style={{
                color:
                  index % 2 === 0
                    ? "white"
                    : currentTheme
                    ? currentTheme.productPageValabilityColor
                    : "rgb(210, 4, 45)",
              }}
            >
              {specification.title}:
            </SpecificationTitle>
            <SpecificationDescription>
              {specification.specification}
            </SpecificationDescription>
          </SingleSpecification>
        );
      })}
    </SpecificationsBody>
  );
};

export default SpecificationsDiv;
