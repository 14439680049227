import React from "react";

const Ingredients = ({ completeProduct }) => {
  return (
    <table className="mt-2 table">
      <thead>
        <tr>
          <th scope="col" className="text-danger">
            Ingrediente descrise:
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {completeProduct.ingredients &&
              completeProduct.ingredients.map((ingredient) => {
                return <span key={ingredient.id}>{ingredient.name}, </span>;
              })}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Ingredients;
