import { useQueryClient } from "@tanstack/react-query";
import { Axios } from "../../../config";

export const useDeleteSubcategory = () => {
  const queryClient = useQueryClient();
  const deleteSubcategory = async (id) => {
    await queryClient.cancelQueries(["getListOfSubcategories"]);

    const previousSubcategories = queryClient.getQueryData([
      "getListOfSubcategories",
    ]);

    queryClient.setQueryData(["getListOfSubcategories"], (oldData) =>
      oldData.filter((subcategory) => subcategory.id !== id)
    );

    try {
      await Axios.delete(`subcategories/${id}`);
    } catch (error) {
      queryClient.setQueryData(
        ["getListOfSubcategories"],
        previousSubcategories
      );
      console.error("Eroare la ștergerea subcategoriei:", error);
    }
  };
  return deleteSubcategory;
};
