import React from "react";
import styled from "@emotion/styled";
import FormikField from "../FormikField";
import { FieldArray } from "formik";

const SpecificationDiv = styled.div`
  display: flex;
  align-items: center;
`;
const ListDiv = styled.div`
  flex-grow: 1;
`;
const RemoveBtn = styled.button`
  width: 80px;
  height: 40px;
`;
const Label = styled.div`
  color: red;
`;
const SpecificationsDiv = ({ name, values }) => {
  return (
    <div>
      <Label htmlFor={name}>Specificatii</Label>
      <FieldArray name={name}>
        {({ push, remove }) => (
          <div className="mb-2">
            {values.specifications.map((_, index) => (
              <SpecificationDiv key={index}>
                <ListDiv>
                  <FormikField
                    name={`specifications[${index}].title`}
                    fieldType="textarea"
                    placeholder="Titlu specificatie"
                  />
                  <FormikField
                    name={`specifications[${index}].specification`}
                    fieldType="textarea"
                    placeholder="Specificatie"
                  />
                </ListDiv>
                <RemoveBtn
                  type="button"
                  className="btn btn-danger"
                  onClick={() => remove(index)}
                >
                  Remove
                </RemoveBtn>
              </SpecificationDiv>
            ))}
            <button
              type="button"
              className="btn btn-info"
              onClick={() => push("")}
            >
              Add Specification
            </button>
          </div>
        )}
      </FieldArray>
    </div>
  );
};

export default SpecificationsDiv;
