import React from "react";
import { Field, ErrorMessage } from "formik";
import styled from "@emotion/styled";

const MyLabel = styled.label`
  color: red;
`;

const FormikField = ({
  name,
  labelText,
  fieldType,
  rows,
  placeholder,
  fieldStyle,
}) => {
  return (
    <>
      <ErrorMessage
        name={name}
        component="div"
        className="alert alert-danger"
      />
      <MyLabel htmlFor={name}>{labelText}</MyLabel>
      {fieldType === "text" && (
        <Field
          name={name}
          type="text"
          className="form-control "
          placeholder={placeholder}
          style={fieldStyle}
        />
      )}
      {fieldType === "textarea" && (
        <Field
          name={name}
          as="textarea"
          className="form-control "
          rows={rows}
          placeholder={placeholder}
          style={fieldStyle}
        />
      )}
      {fieldType === "number" && (
        <Field
          name={name}
          type="number"
          className="form-control "
          placeholder={placeholder}
          style={fieldStyle}
        />
      )}
    </>
  );
};

export default FormikField;
