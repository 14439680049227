import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Axios } from "../../../config";

export const useAddCategory = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async (newCategory) =>
      await Axios.post("categories", newCategory).then((res) => {
        console.log(res.data);
      }),
    onMutate: (newCategory) => {
      const previousCategories = queryClient.getQueryData([
        "getListOfCategories",
      ]);
      queryClient.setQueryData(["getListOfCategories"], (oldData) => [
        ...(oldData || []),
        { id: Date.now(), ...newCategory },
      ]);
      return { previousCategories };
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["getListOfCategories"]);
      navigate("/admin/lista-categorii");
    },
    onError: (error, context) => {
      queryClient.setQueryData(
        ["getListOfCategories"],
        context.previousCategories
      );
      console.error("Eroare la adăugarea categoriei:", error);
    },
  });
};
