import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Axios } from "../../../config";

export const useActivateOrInactivateProductMutation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: async (id) => {
      const response = await Axios.patch(
        `products/activateOrInactivateProduct/${id}`
      );
      return response.data;
    },
    onMutate: (updatedCategory) => {},
    onSuccess: (response) => {
      const backendResponse = response;
      console.log("Produsul activat sau inactivat in DB:", backendResponse);
      const affectedMenuItemCategoryCombinationArray =
        backendResponse.affectedMenuItemCategoryCombinationArray;
      queryClient.refetchQueries(["getProductsWithNameImage"]);

      affectedMenuItemCategoryCombinationArray.forEach((item) => {
        console.log("menuItemId:", item.menuItemId);
        console.log("categoryId:", item.categoryId);
        const exactQueryKey = [
          "getProductsConditionally",
          item.menuItemId,
          item.categoryId,
        ];
        queryClient.refetchQueries({
          queryKey: exactQueryKey,
          exact: true, // This ensures only the exact query key match is refetched
        });
      });
    },
    onError: (error, context) => {
      console.error("Eroare la activarea sau inactivarea produsului:", error);
    },
  });
};
