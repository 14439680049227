import * as Yup from "yup";

//AddSubcategory
export const addSubcategoryValidationSchema = Yup.object({
  selectedMenuItemId: Yup.string().required("Selectează un item din meniu"),

  selectedCategoryId: Yup.string().required("Selectează o categorie"),

  name: Yup.string()

    .min(2, "Numele subcategoriei trebuie sa aiba minim 2 caractere")
    .required("Numele subcategoriei este obligatoriu"),
});
