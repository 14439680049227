import React from "react";

const ImageAndName = ({ completeProduct }) => {
  return (
    <table className="table mt-2">
      <thead>
        <tr>
          <th scope="col" className="text-danger">
            Imagine
          </th>
          <th scope="col" className="text-danger">
            Nume
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {completeProduct.productImage && (
              <img
                src={completeProduct.productImage.src}
                style={{ width: "60px" }}
                alt="product-image"
              />
            )}
          </td>
          <td>{completeProduct.name}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default ImageAndName;
