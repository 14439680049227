export  const customOption = (props) => {
    const { data, innerRef, innerProps, isSelected, isFocused } = props;

    return (
      <div
        ref={innerRef}
        {...innerProps}
        style={{
          display: "flex",
          alignItems: "center",
          color: "black",
          fontWeight: "400",
          padding: "6px 10px",
          cursor: "pointer",
          ...(isFocused && {
            backgroundColor: "rgb(230, 248, 255)",
          }),
        }}
      >
        <span style={{ marginRight: "10px" }}>{data.icon}</span>
        <span>{data.label}</span>
      </div>
    );
  };