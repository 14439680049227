import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Axios } from "../../../config";

export const useEditMenuItemMutation = (id) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: async (data) =>
      await Axios.patch(`menuitems/${id}`, data).then((res) => {
        console.log("Raspuns de la backend dupa editare:", res.data);
      }),
    onMutate: async (updatedMenuItem) => {
      await queryClient.cancelQueries(["getListOfMenuItems"]);
      const previousMenuItems = queryClient.getQueryData([
        "getListOfMenuItems",
      ]);

      queryClient.setQueryData(["getListOfMenuItems"], (oldMenuItems = []) =>
        oldMenuItems.map((item) =>
          item.id === id ? { ...updatedMenuItem, id: id } : item
        )
      );

      return { previousMenuItems };
    },
    onError: (err, updatedMenuItem, context) => {
      queryClient.setQueryData(
        ["getListOfMenuItems"],
        context.previousMenuItems
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["getListOfIngredients"]);
      navigate("/admin/lista-menu-items");
    },
  });
};
