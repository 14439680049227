import React from "react";
import styled from "@emotion/styled";
import ThemeImagesList from "./ThemeImagesList";

const ThemeImagesSection = styled.div`
  width: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const DisplayThemeImagesForSelection = ({
  section,
  showThemeImages,
  handleImageSelect,
  setFieldValue,
}) => {
  return (
    <ThemeImagesSection>
      <h6 style={{ color: "blue" }}>Selecteaza imaginea:</h6>
      {section.sectionImages.map((image) => {
        return (
          <div key={image.id} style={{ width: "100%" }}>
            {showThemeImages === image.imageFieldName && (
              <ThemeImagesList
                onSelect={(selectedImage) =>
                  handleImageSelect(
                    image.imageFieldName,
                    selectedImage,
                    setFieldValue
                  )
                }
              />
            )}
          </div>
        );
      })}
    </ThemeImagesSection>
  );
};

export default DisplayThemeImagesForSelection;
