import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { useMenuItemsData } from "../../../Contexts/MenuItemsDataContext";
import { useCategoriesData } from "../../../Contexts/CategoriesDataContext";
import { getCategoryById } from "../../myFunctions";
import { getSelectListMenuItemsOptions } from "../../myFunctions";
import MenuItemsSelectList from "../../Components/AddCategory/MenuItemSelectList";
import FormikField from "../../Components/FormikField";
import { useEditCategoryMutation } from "../../../myHooks/myMutations/editMutations/editCategoryMutation";
import { editCategoryValidationSchema } from "../../validationSchemas/category/editCategoryValidationSchema";
import { editCategoryInitialValues } from "../../initialValuesFormik/category/editCategoryInitialValues";
const EditCategory = () => {
  const { id } = useParams();
  const [currentCategory, setCurrentCategory] = useState();
  const [menuItemsOptions, setMenuItemsOptions] = useState();
  const [initialValues, setInitialValues] = useState({
    name: "",
    selectedMenuItemId: "",
    selectedMenuItemOption: { value: "", label: "" },
    path: "",
  });
  const { data: menuItemsData } = useMenuItemsData();
  const { data: categoriesData } = useCategoriesData();
  const mutation = useEditCategoryMutation(id);

  useEffect(() => {
    if (categoriesData && id) {
      const currentCategory = getCategoryById(id, categoriesData);
      const menuItemsOptions = getSelectListMenuItemsOptions(menuItemsData);
      setCurrentCategory(currentCategory);
      setMenuItemsOptions(menuItemsOptions);
      if (currentCategory && menuItemsOptions && menuItemsOptions.length > 0) {
        const currentMenuItemOption = menuItemsOptions.find(
          (option) => option.value === currentCategory.menuItemId
        );
        const initialValues = editCategoryInitialValues(
          currentCategory,
          currentMenuItemOption,
          setInitialValues
        );
        setInitialValues(initialValues);
      }
    }
  }, [categoriesData, menuItemsData, id]);

  const onSubmit = (data) => {
    const newCategory = {
      name: data.name,
      path: data.path,
      menuItemId: data.selectedMenuItemId,
    };
    mutation.mutate(newCategory);
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center w-100 ">
      <h5>Editare categorie</h5>
      {currentCategory && menuItemsOptions && (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={editCategoryValidationSchema}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form style={{ width: "60%" }}>
              <FormikField name="name" labelText="Nume" fieldType="text" />
              <FormikField name="path" labelText="Path" fieldType="text" />
              <MenuItemsSelectList
                name="selectedMenuItemId"
                options={menuItemsOptions}
                value={values.selectedMenuItemOption}
                onMenuItemChange={(selectedOption) => {
                  setFieldValue("selectedMenuItemId", selectedOption.value);
                  setFieldValue("selectedMenuItemOption", selectedOption);
                }}
              />
              <button type="submit" className="btn btn-primary">
                Editeaza
              </button>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default EditCategory;
