// MenuItemsContext.js
import React, { createContext, useContext } from "react";
import useGetIngredientsData from "../myHooks/ingredient/useGetIngredientsData";

// Creează contextul
const IngredientsDataContext = createContext();

// Creează un custom hook pentru a folosi contextul
export const useIngredientsData = () => {
  return useContext(IngredientsDataContext);
};

// Creează providerul pentru context
export const IngredientsDataProvider = ({ children }) => {
  const {
    data: ingredientsData,
    isLoading: isLoadingIngredientsData,
    isError: isErrorIngredientsData,
    error: errorIngredientsData,
  } = useGetIngredientsData();

  return (
    <IngredientsDataContext.Provider
      value={{
        data: ingredientsData,
        isLoading: isLoadingIngredientsData,
        isError: isErrorIngredientsData,
        error: errorIngredientsData,
      }}
    >
      {children}
    </IngredientsDataContext.Provider>
  );
};
