import React from "react";
import styled from "@emotion/styled";

const UseInstructions = styled.div`
  margin-top: 20px;
`;

const UseInstructionsTitle = styled.div``;
const UseInstructionsList = styled.ul``;
const Instruction = styled.li``;

const UseInstructionsDiv = ({ product, currentTheme }) => {
  return (
    <>
      {product.useInstructions && product.useInstructions.length > 0 && (
        <UseInstructions>
          <UseInstructionsTitle
            style={{
              color: currentTheme
                ? currentTheme.productPageValabilityColor
                : "rgb(210, 4, 45)",
              fontWeight: "600",
            }}
          >
            Recomandări de utilizare:
          </UseInstructionsTitle>
          <UseInstructionsList>
            {product.useInstructions.map((instruction) => {
              return <Instruction>{instruction.instruction}</Instruction>;
            })}
          </UseInstructionsList>
        </UseInstructions>
      )}
    </>
  );
};

export default UseInstructionsDiv;
