import React, { useEffect, useState } from "react";
import { getSelectListMenuItemsOptions } from "../../myFunctions";
import { useMenuItemsData } from "../../../Contexts/MenuItemsDataContext";
import Select from "react-select";
import styled from "@emotion/styled";

const MySelectList = styled.div``;
const MyLabel = styled.label`
  color: red;
`;

const MenuItemsSelectList = ({ value, onMenuItemChange, name }) => {
  const [menuItemsOptions, setMenuItemsOptions] = useState([]);
  const { data: menuItemsData } = useMenuItemsData();

  useEffect(() => {
    if (menuItemsData) {
      const options = getSelectListMenuItemsOptions(menuItemsData);
      setMenuItemsOptions(options);
    }
  }, [menuItemsData]);
  return (
    <>
      {menuItemsOptions && menuItemsOptions.length > 0 && (
        <MySelectList>
          <MyLabel htmlFor={name}>Selecteaza menu item</MyLabel>
          <Select
            name={name}
            options={menuItemsOptions}
            value={value}
            onChange={onMenuItemChange}
          />
        </MySelectList>
      )}
    </>
  );
};

export default MenuItemsSelectList;
