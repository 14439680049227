import React, { createContext, useContext } from "react";
import useGetThemesData from "../myHooks/theme/useGetThemesData";

// Creează contextul
const ThemesDataContext = createContext();

// Creează un custom hook pentru a folosi contextul
export const useThemesData = () => {
  return useContext(ThemesDataContext);
};

// Creează providerul pentru context
export const ThemesDataProvider = ({ children }) => {
  const {
    data: themesData,
    isLoading: isLoadingThemesData,
    isError: isErrorThemesData,
    error: errorThemesData,
  } = useGetThemesData();

  return (
    <ThemesDataContext.Provider
      value={{
        data: themesData,
        isLoading: isLoadingThemesData,
        isError: isErrorThemesData,
        error: errorThemesData,
      }}
    >
      {children}
    </ThemesDataContext.Provider>
  );
};
