import React, { useEffect, useRef } from "react";
import { components } from "react-select";


export const CustomMenu = (props) => {
  const menuRef = useRef(null);

  useEffect(() => {
    if (menuRef.current) {
      console.log("Menu height:", menuRef.current.offsetHeight);
    }
  }, [props.children]);

  return (
    <components.Menu {...props} innerRef={menuRef}>
      {props.children}
    </components.Menu>
  );
};