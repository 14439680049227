import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { getIngredientsStyles } from "./getIngredientsStyles";
import { css, keyframes } from "@emotion/react";
import Select, { components } from "react-select";
import { FaChevronDown } from "react-icons/fa";
import { CustomMenu } from "./CustomMenu";
import { customOption } from "./customOption";

const SelectIngredientDiv = styled.div`
  width: 100%;
  //overflow: hidden;
`;
const appearIngredientDiv = keyframes`
from{
opacity:0;
}
to{
opacity:1;}`;
const IngredientDiv = styled.div`
  display: none;
  animation: ${appearIngredientDiv} 1s ease forwards;
`;
const IngredientName = styled.span`
  font-weight: 600;
`;
const IngredientDescription = styled.span``;

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaChevronDown style={{ color: "black" }} />
    </components.DropdownIndicator>
  );
};

const IngredientsDiv = ({ product, currentTheme }) => {
  const [ingredientsOptions, setIngredientsOptions] = useState([]);
  const [selectedIngredientId, setSelectedIngredientId] = useState(0);
  const [selectContainerHeight, setSelectContainerHeight] = useState("auto");
  const [ingredientDivStyle, setIngredientDivStyle] = useState({});
  const styles = getIngredientsStyles(currentTheme);

  useEffect(() => {
    if (product) {
      const options = product.ingredients.map((item) => {
        return { value: item.id, label: item.name };
      });
      setIngredientsOptions(options);
    }
  }, [product]);
  return (
    <>
      <div
        style={{
          color: currentTheme
            ? currentTheme.productPageValabilityColor
            : "rgb(210, 4, 45)",

          fontWeight: "600",
          marginTop: "10px",
          marginBottom: "5px",
        }}
      >
        Descrierea ingredientelor:
      </div>
      {ingredientsOptions && ingredientsOptions.length > 0 && (
        <SelectIngredientDiv
          style={{
            transition: "height 0.9s ease",
            height: selectContainerHeight,
            overflow: "hidden", // Prevent overflow when closed
            padding: "5px 0px",
          }}
        >
          <Select
            menuPortalTarget={document.body}
            onMenuOpen={() => {
              setSelectContainerHeight("260px");
            }}
            onMenuClose={() => setSelectContainerHeight("auto")}
            options={ingredientsOptions}
            getOptionLabel={(e) => e.label}
            getOptionValue={(e) => e.value}
            styles={styles}
            placeholder="Selectează ingredient"
            components={{
              DropdownIndicator: DropdownIndicator,
              Option: customOption,
              Menu: CustomMenu,
            }}
            onChange={(selectedOption) => {
              setSelectedIngredientId(selectedOption.value);
              setIngredientDivStyle({
                color: selectedOption.color || "black",
                backgroundColor: selectedOption.bgColor || "white",
                border: `1px solid  ${
                  currentTheme
                    ? currentTheme.productListPageSelectListControlBorderColor
                    : "grey"
                }`,
                padding: "10px",
                display: "block",
                borderRadius: "0 0 10px 10px",

                // transition: "1s",
              });
            }}
          />
          {product &&
            product.ingredients.map((item) => {
              if (item.id === selectedIngredientId) {
                return (
                  <IngredientDiv
                    key={item.id}
                    style={{
                      ...ingredientDivStyle,
                    }}
                  >
                    <IngredientDescription>
                      {item.description}
                    </IngredientDescription>
                  </IngredientDiv>
                );
              }
            })}
        </SelectIngredientDiv>
      )}
    </>
  );
};

export default IngredientsDiv;
