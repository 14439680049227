import React from 'react'
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";

const StyledBootstrapImage = styled(Card.Img)`
  width: 190px;
  margin-top: 15px;
`;
const CardImage = styled(Link)``;

const ProductImage = ({currentProduct}) => {
  return (
    <CardImage to={`/produse/${currentProduct.id}`}>
    <StyledBootstrapImage
      className=" hover-shadow"
      variant="top"
      src={currentProduct ? currentProduct.imageSrc : null}
      alt="product-image-card"
    />
  </CardImage>
  )
}

export default ProductImage