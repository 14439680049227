import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import CineSuntEuSection from "../Components/Home/CineSuntEuSection";
import GallerySection from "../Components/Home/GallerySection";
import HeaderDiv from "../Components/Home/HeaderDiv";

const Wrap = styled.div`
  width: 100%;
  margin-top: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 25px;
  font-weight: 500;
  text-align: center;
`;
const ImagesSection = styled.div`
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const ImageSectionContent = styled.div``;
const ImageSectionText = styled(Container)`
  padding: 20px;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
  @media screen and (min-width: 600px) {
    width: 60%;
  }
`;

const Home = ({ currentTheme }) => {
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);
  return (
    <Wrap>
      <HeaderDiv currentTheme={currentTheme} />
      <CineSuntEuSection currentTheme={currentTheme} />
      <ImagesSection
        style={{
          backgroundColor: currentTheme
            ? currentTheme.homePageSoapsSectionBackgroundColor
            : "white",
        }}
      >
        <ImageSectionContent>
          <Title>Câteva din săpunurile mele favorite</Title>
          <ImageSectionText>
            A fost o perioadă când eram fascinată de săpun, făceam unul la 2
            zile, experimentând cu rețete, forme și culori. A fost o joacă
            superbă și din acea joacă au ieșit aceste modele, colorate ori cu
            pudre naturale, ori cu pigment mineral. Am ajuns chiar să-mi fac
            propriile matrițe. Ce înseamnă să ai timp!
          </ImageSectionText>
          <GallerySection />
        </ImageSectionContent>
      </ImagesSection>
    </Wrap>
  );
};

export default Home;
