import React,{useEffect, useState} from 'react'
import styled from "@emotion/styled";
import Select from "react-select";

const Price = styled.div`
  display: flex;
  align-items: center;
`;
const CurrentPrice = styled.div`
  font-weight: 600;
  font-size: 16px;
`;

const Quantity = styled.div`
  padding: 3px;
`;

const SelectPriceDiv = ({currentTheme,currentProduct}) => {
    const [selectOptions, setSelectOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [displayedPrice, setDisplayedPrice] = useState(null);

    useEffect(() => {
       if(currentProduct){ 
        const options=currentProduct.prices.map(item=>{
        return(
            {
                value:item.id,
                label:item.quantity+ currentProduct.unitOfMeasure,
                price:item.price
            }
        )
    })
    setSelectOptions(options);
    setDisplayedPrice(options[0].price)
    setSelectedOption(options[0])
  }
    },[currentProduct])

    const handleQuantityChange = (selectedOption) => {   
    
        setSelectedOption(selectedOption);
        setDisplayedPrice(selectedOption.price);
      };
  return (
   <>
      <Price>
              <CurrentPrice
                style={{
                  color: currentTheme
                    ? ` ${currentTheme.cardPriceColor}`
                    : "rgba(143, 30, 53, 255)",
                }}
              >
                {displayedPrice}
                <span
                  style={{
                    color: currentTheme
                      ? ` ${currentTheme.cardPriceColor}`
                      : "rgba(143, 30, 53, 255)",
                    marginLeft: "1px",
                  }}
                >
                  Ron
                </span>
              </CurrentPrice>
         
            </Price>
            {selectOptions && selectOptions.length > 0 && (
              <Quantity>
                <Select
                  value={selectedOption}
                  options={selectOptions}
                  onChange={(selectedOption) =>
                    handleQuantityChange(selectedOption)
                  }
                  style={{
                    borderRadius: "4px",
                    padding: "5px 8px",
                    marginLeft: "6px",
                    backgroundColor: currentTheme
                      ? ` ${currentTheme.cardBackgroundColor}`
                      : "white",
                  }}
                ></Select>
              </Quantity>
            )}      
   </>
  )
}

export default SelectPriceDiv