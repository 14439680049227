import React from "react";
import styled from "@emotion/styled";
import FormikField from "../FormikField";
import { FieldArray } from "formik";

const Label = styled.div`
  color: red;
`;
const RemoveBtn = styled.button`
  width: 80px;
  height: 40px;
`;
const DescriptionsDiv = ({ name, values }) => {
  return (
    <div>
      <Label htmlFor={name}>Descrieri</Label>
      <FieldArray name={name}>
        {({ push, remove }) => (
          <div className="mb-2">
            {values.descriptions.map((_, index) => (
              <div key={index} className="d-flex align-items-center mb-1">
                <FormikField
                  name={`descriptions[${index}]`}
                  placeholder="Descriere produs"
                  fieldType="textarea"
                />
                <RemoveBtn
                  type="button"
                  className="btn btn-danger"
                  onClick={() => remove(index)}
                >
                  Remove
                </RemoveBtn>
              </div>
            ))}
            <button
              type="button"
              className="btn btn-info"
              onClick={() => push("")}
            >
              Add Description
            </button>
          </div>
        )}
      </FieldArray>
    </div>
  );
};

export default DescriptionsDiv;
