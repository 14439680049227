import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Axios } from "../../../config";

export const useEditThemeMutation = (id) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: async (data) => {
      const response = await Axios.patch(`themes/${id}`, data);
      return response.data;
    },

    onMutate: async (newTheme) => {
      const previousThemes = queryClient.getQueryData(["getListOfThemes"]);
      queryClient.setQueryData(["getListOfThemes"], (oldData = []) => {
        return oldData.map((theme) =>
          parseInt(theme.id, 10) === parseInt(id, 10)
            ? { ...theme, ...newTheme }
            : theme
        );
      });
      return { previousThemes };
    },
    onError: (error, newTheme, context) => {
      queryClient.setQueryData(["getListOfThemes"], context.previousThemes);
      console.error("Error updating theme:", error);
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries(["getListOfThemes"]);
      navigate("/admin/lista-teme");
      console.log("Theme updated successfully:", response.data);
    },
  });
};
