import React from "react";
import MenuItemSelectList from "../../Components/EditProduct/MenuItemSelectList";
import CategorySelectList from "../../Components/EditProduct/CategorySelectList";
import SubcategorySelectList from "../../Components/EditProduct/SubcategorySelectList";
import { useMenuItemsData } from "../../../Contexts/MenuItemsDataContext";
import { useCategoriesData } from "../../../Contexts/CategoriesDataContext";
import { useSubcategoriesData } from "../../../Contexts/SubcategoriesDataContext";

const SelectListsDiv = ({ setFieldValue, values }) => {
  const { data: menuItemsData } = useMenuItemsData();
  const { data: categoriesData } = useCategoriesData();
  const { data: subcategoriesData } = useSubcategoriesData();
  return (
    <div>
      <MenuItemSelectList
        setFieldValue={setFieldValue}
        values={values}
        name="selectedMenuItemsIds"
        menuItemsData={menuItemsData}
        categoriesData={categoriesData}
      />
      {values.selectedMenuItemsCategoriesSubcategories &&
        values.selectedMenuItemsCategoriesSubcategories.length > 0 &&
        values.selectedMenuItemsCategoriesSubcategories.map(
          (menuItem, menuItemIndex) => {
            return (
              <div key={menuItemIndex}>
                {menuItem.filteredCategoriesObj &&
                  menuItem.filteredCategoriesObj.length > 0 && (
                    <div>
                      <CategorySelectList
                        menuItem={menuItem}
                        menuItemIndex={menuItemIndex}
                        name={`selectedMenuItemsCategoriesSubcategories[${menuItemIndex}].categories`}
                        setFieldValue={setFieldValue}
                        values={values}
                        categoriesData={categoriesData}
                        subcategoriesData={subcategoriesData}
                      />
                      {menuItem.categories &&
                        menuItem.categories.length > 0 &&
                        menuItem.categories.map((category, categoryIndex) => {
                          return (
                            <div key={categoryIndex}>
                              {category.filteredSubcategoriesObj &&
                                category.filteredSubcategoriesObj.length >
                                  0 && (
                                  <SubcategorySelectList
                                    menuItem={menuItem}
                                    category={category}
                                    name={`selectedMenuItemsCategoriesSubcategories[${menuItemIndex}].categories[${categoryIndex}.subcategoriesIds]`}
                                    setFieldValue={setFieldValue}
                                    values={values}
                                    categoryIndex={categoryIndex}
                                    menuItemIndex={menuItemIndex}
                                    subcategoriesData={subcategoriesData}
                                  />
                                )}
                            </div>
                          );
                        })}
                    </div>
                  )}
              </div>
            );
          }
        )}
    </div>
  );
};

export default SelectListsDiv;
