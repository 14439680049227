import { useQueryClient } from "@tanstack/react-query";
import { Axios } from "../../../config";

export const useDeleteProduct = () => {
  const queryClient = useQueryClient();

  const deleteProduct = async (id) => {
    // await queryClient.cancelQueries(["getListOfIngredients"]);

    // const previousIngredients = queryClient.getQueryData([
    //   "getListOfIngredients",
    // ]);

    // queryClient.setQueryData(["getListOfIngredients"], (oldData) =>
    //   oldData.filter((ingredient) => ingredient.id !== id)
    // );

    try {
      const response = await Axios.delete(`products/${id}`);
      console.log("Produsul sters:", response.data);

      queryClient.invalidateQueries(["getProductsWithNameImage"]);
    } catch (error) {
      // queryClient.setQueryData(["getListOfIngredients"], previousIngredients);
      console.error("Eroare la ștergerea produsului:", error);
    }
  };
  return deleteProduct;
};
