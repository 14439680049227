import React, { useState } from "react";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import LeftImage from "./LeftImage";
import Lucilles from "./Lucilles";
import Hambourger from "./Hambourger";
import RightImage from "./RightImage";
import Sidenav from "./Sidenav/Sidenav";

const appear = keyframes`
from{
  opacity:0;}
  to{
    opacity:1;}
  
  `;
const Navbar = styled.div`
  animation: ${appear} 1.5s ease forwards;
  width: 100%;
  height: 90px;
  position: fixed;
  top: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 1000px) {
    display: none;
  }
`;
const NavContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 600px) {
    justify-content: start;
  }
`;

const MobileNavbar = ({ currentTheme }) => {
  const [sideNav, setSideNav] = useState(false);

  return (
    <Navbar
      style={{
        backgroundColor: currentTheme
          ? `${currentTheme.headerColor}`
          : " rgba(243, 218, 214, 1)",
      }}
    >
      <LeftImage currentTheme={currentTheme} />
      <NavContent>
        <Lucilles currentTheme={currentTheme} />
        <Hambourger
          currentTheme={currentTheme}
          setSideNav={setSideNav}
          sideNav={sideNav}
        />
      </NavContent>
      {sideNav && (
        <Sidenav
          setSideNav={setSideNav}
          sideNav={sideNav}
          currentTheme={currentTheme}
        />
      )}
      <RightImage currentTheme={currentTheme} />
    </Navbar>
  );
};

export default MobileNavbar;
