import React from "react";
import { Link } from "react-router-dom";

const MiddleImage = ({ currentTheme }) => {
  return (
    <>
      {currentTheme && currentTheme.headerCentralImgSrc && (
        <Link to="/">
          <img
            src={currentTheme?.headerCentralImgSrc}
            alt="logo"
            style={{ height: "75px" }}
          />
        </Link>
      )}
    </>
  );
};

export default MiddleImage;
