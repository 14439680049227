import React, { createContext, useContext } from "react";
import useGetSubcategoriesData from "../myHooks/subcategory/useGetSubcategoriesData";

// Creează contextul
const SubcategoriesDataContext = createContext();

// Creează un custom hook pentru a folosi contextul
export const useSubcategoriesData = () => {
  return useContext(SubcategoriesDataContext);
};

// Creează providerul pentru context
export const SubcategoriesDataProvider = ({ children }) => {
  const {
    data: subcategoriesData,
    isLoading: isLoadingSubcategoriesData,
    isError: isErrorSubcategoriesData,
    error: errorSubcategoriesData,
  } = useGetSubcategoriesData();

  return (
    <SubcategoriesDataContext.Provider
      value={{
        data: subcategoriesData,
        isLoading: isLoadingSubcategoriesData,
        isError: isErrorSubcategoriesData,
        error: errorSubcategoriesData,
      }}
    >
      {children}
    </SubcategoriesDataContext.Provider>
  );
};
