import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { getMenuItemById } from "../../myFunctions";
import { useMenuItemsData } from "../../../Contexts/MenuItemsDataContext";
import { editMenuItemInitialValues } from "../../initialValuesFormik/menuItem/editMenuItemInitialValues";
import { editMenuItemValidationSchema } from "../../validationSchemas/menuItem/editMenuItemValidationSchema";
import { useEditMenuItemMutation } from "../../../myHooks/myMutations/editMutations/editMenuItemMutation";
import FormikField from "../../Components/FormikField";

const EditMenuItem = () => {
  const { id } = useParams();
  const { data: menuItemsData } = useMenuItemsData();
  const [initialValues, setInitialValues] = useState({});
  const [currentMenuItem, setCurrentMenuItem] = useState();

  useEffect(() => {
    if (menuItemsData) {
      const currentMenuItem = getMenuItemById(id, menuItemsData);
      if (currentMenuItem) {
        editMenuItemInitialValues(currentMenuItem, setInitialValues);
      }
      setCurrentMenuItem(currentMenuItem);
    }
  }, [menuItemsData, id]);

  const mutation = useEditMenuItemMutation(id);

  const onSubmit = (data) => {
    mutation.mutate(data);
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center w-100">
      <div style={{ width: "80%" }}>
        <h5 className="text-center">Editează menu item:</h5>
        {currentMenuItem && (
          <Formik
            initialValues={initialValues}
            validationSchema={editMenuItemValidationSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            <Form>
              <FormikField name="name" labelText="Nume" fieldType="text" />
              <FormikField
                name="hasdropdown"
                labelText="Hasdropdown"
                fieldType="text"
              />
              <FormikField name="path" labelText="Path" fieldType="text" />
              <button type="submit" className="btn btn-primary mt-2">
                Editeaza
              </button>
            </Form>
          </Formik>
        )}
      </div>
    </div>
  );
};

export default EditMenuItem;
