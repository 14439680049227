import React from "react";
import styled from "@emotion/styled";
import { FaBars } from "react-icons/fa";

const HambourgerIcon = styled.div`
  padding: 10px;
  border-radius: 7px;
  font-size: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 30px;
  z-index: 3;

  box-shadow: rgba(50, 50, 93, 0.5) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.7) 0px 8px 16px -8px;

  /* @media screen and (min-width: 1000px) {
    display: none;
  } */
`;
const Hambourger = ({ currentTheme, setSideNav, sideNav }) => {
  return (
    <HambourgerIcon onClick={() => setSideNav(!sideNav)}>
      <FaBars
        style={{
          color: currentTheme ? currentTheme.hambourgerColor : "black",
        }}
      />
    </HambourgerIcon>
  );
};

export default Hambourger;
