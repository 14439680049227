import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { allFieldNames, themeSections } from "../../../Data/themeVariables";
import { useThemesData } from "../../../Contexts/ThemesDataContext";
import ColorsSection from "../../Components/ThemeComponents/ColorsSection";
import SelectThemeImagesSection from "../../Components/ThemeComponents/SelectThemeImagesSection";
import SubmitButton from "../../Components/ThemeComponents/SubmitButton";
import ThemeNameInput from "../../Components/ThemeComponents/ThemeNameInput";
import { useEditThemeMutation } from "../../../myHooks/myMutations/editMutations/editThemeMutation";
const ThemeSectionsWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
`;

const Section = styled.div`
  width: 90%;

  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const EditTheme = () => {
  const { id } = useParams();
  const [showThemeImages, setShowThemeImages] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [isActiveBtn, setIsActiveBtn] = useState("");
  const { data: themesData } = useThemesData();
  const [currentTheme, setCurrentTheme] = useState({});
  const [initialValues, setInitialValues] = useState(
    allFieldNames.reduce((acc, field) => {
      acc[field] = "";
      return acc;
    }, {})
  );
  const mutation = useEditThemeMutation(id);
  //Gasesc tema curenta:
  useEffect(() => {
    if (themesData && id) {
      const theme = themesData.find(
        (theme) => parseInt(theme.id, 10) === parseInt(id, 10)
      );
      setCurrentTheme(theme);
    }
  }, [themesData, id]);

  useEffect(() => {
    if (currentTheme) {
      const initialValues = allFieldNames.reduce((acc, field) => {
        acc[field] = currentTheme[field] ?? "";
        return acc;
      }, {});
      initialValues.isActive = false;
      setInitialValues(initialValues);
    }
  }, [currentTheme, id]);

  const handleImageSelect = (imageFieldName, image, setFieldValue) => {
    setShowThemeImages("");
    setFieldValue(imageFieldName, image.src);
    selectedImages.push({
      id: image.id,
      src: image.src,
      fieldName: imageFieldName,
    });
  };

  const onSubmit = (data) => {
    mutation.mutate(data);
  };
  return (
    <div className="d-flex flex-column align-items-center justify-content-center w-100">
      <h3 className="mb-4 mt-4 ">Editeaza tema : </h3>
      {currentTheme && initialValues && (
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { resetForm }) => onSubmit(values, resetForm)}
          enableReinitialize={true}
        >
          {({ setFieldValue, values }) => {
            console.log("values", values);
            return (
              <Form
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <SubmitButton btnText={"Editeaza tema"} />
                {/* ------------------------NUME TEMA-------------------- */}
                <ThemeNameInput
                  fieldName={"themeName"}
                  labelText={"Nume tema"}
                />
                <ThemeSectionsWrap>
                  {themeSections.map((section) => {
                    return (
                      <Section key={section.id}>
                        <h5 style={{ color: "blue" }} className="mt-4">
                          {section.name}
                        </h5>
                        {/* ------------------------SECTIUNEA CULORI-------------------- */}
                        <ColorsSection section={section} values={values} />

                        {/* ------------------------SECTIUNEA IMAGINI-------------------- */}
                        <SelectThemeImagesSection
                          section={section}
                          setShowThemeImages={setShowThemeImages}
                          isActiveBtn={isActiveBtn}
                          setIsActiveBtn={setIsActiveBtn}
                          showThemeImages={showThemeImages}
                          handleImageSelect={handleImageSelect}
                          setFieldValue={setFieldValue}
                          selectedImages={selectedImages}
                          currentTheme={currentTheme}
                          page="EditTheme"
                        />
                      </Section>
                    );
                  })}
                </ThemeSectionsWrap>
                <SubmitButton btnText={"Editeaza tema"} />
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default EditTheme;
