import React from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";

const NavLinkItem = styled(Link)`
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
`;

const NoDropdownMenuItem = ({ currentTheme, menuItem }) => {
  return (
    <NavLinkItem
      key={menuItem.id}
      to={menuItem.path}
      style={{
        color: currentTheme ? `${currentTheme.headerTextColor}` : "white",
      }}
    >
      {menuItem.name}
    </NavLinkItem>
  );
};

export default NoDropdownMenuItem;
