export const editCategoryInitialValues = (
  currentCategory,
  currentMenuItemOption
) => {
  const initialValues = {
    name: currentCategory.name,
    selectedMenuItemId: currentCategory.menuItemId,
    selectedMenuItemOption: currentMenuItemOption,
    path: currentCategory.path,
  };
  return initialValues;
};
