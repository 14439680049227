import React, { useState, useEffect } from "react";
import { useCategoriesData } from "../../../../Contexts/CategoriesDataContext";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { useRef } from "react";

const DropdownContent = styled.div`
  position: absolute;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  border-radius: 0 0 8px 8px;
  margin-top: 15px;
`;

const NavLinkItem = styled(Link)`
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
`;

const DropdownDiv = ({
  menuItem,
  currentTheme,
  handleDropdownToggle,
  openDropdown,
  setOpenDropdown,
}) => {
  const dropdownRef = useRef(null);
  const { data: categoriesData } = useCategoriesData();

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenDropdown(false);
    }
  };

  useEffect(() => {
    if (openDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup event listener on component unmount or when dropdown closes
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openDropdown]);

  const categoriesAfterMenuItem = categoriesData.filter((category) => {
    return category.menuItemId === menuItem.id;
  });
  return (
    <>
      {openDropdown === menuItem.name && (
        <DropdownContent
          ref={dropdownRef}
          style={{
            backgroundColor: currentTheme
              ? `${currentTheme.headerColor}`
              : " rgb(40, 110, 160)",
          }}
        >
          {categoriesAfterMenuItem &&
            categoriesAfterMenuItem.length > 0 &&
            categoriesAfterMenuItem.map((category) => {
              return (
                <NavLinkItem
                  key={category.id}
                  to={`${menuItem.path}/${category.path}`}
                  onClick={() => handleDropdownToggle(null)}
                  style={{
                    color: currentTheme
                      ? `${currentTheme.headerTextColor}`
                      : "white",
                  }}
                >
                  {category.name}
                </NavLinkItem>
              );
            })}
        </DropdownContent>
      )}
    </>
  );
};

export default DropdownDiv;
