import React from "react";
import { useQuery } from "@tanstack/react-query";
import { Axios } from "../../config";

const useGetProductsConditionally = (menuItemId, categoryId) => {
  // console.log("menuItemId:", menuItemId);
  // console.log("categoryId:", categoryId);
  const fetchProducts = async () => {
    const endpoint = `products/getProductsForProductListPage/${menuItemId}/${
      categoryId === undefined ? "undefined" : categoryId
    }`;
    const { data } = await Axios.get(endpoint);
    return data;
  };

  return useQuery({
    queryKey: ["getProductsConditionally", menuItemId, categoryId],
    queryFn: fetchProducts,
    staleTime: 0, // Consider data immediately stale
    cacheTime: 10 * 60 * 1000, // Keep in cache for 10 minutes
    refetchOnMount: true, // Refetch when component mounts
    enabled: true,
  });
};

export default useGetProductsConditionally;
