import React from 'react'
import styled from "@emotion/styled";
import { Card } from "react-bootstrap";

const StyledCardTitle = styled(Card.Title)`
  font-size: 16px;
`;

const CardTitle = ({currentProduct,currentTheme}) => {
  return (
    <StyledCardTitle
    style={{
      color: currentTheme
        ? ` ${currentTheme.cardTitleColor}`
        : "rgba(143, 30, 53, 255)",
      width: "90%",
      fontWeight: "600",
     
    }}
  >
    {currentProduct.name}
  </StyledCardTitle>
  )
}

export default CardTitle