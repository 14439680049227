import React, { createContext, useContext } from "react";
import useGetThemeImagesData from "../myHooks/themeImage/useGetThemeImagesData";

// Creează contextul
const ThemeImagesDataContext = createContext();

// Creează un custom hook pentru a folosi contextul
export const useThemeImagesData = () => {
  return useContext(ThemeImagesDataContext);
};

// Creează providerul pentru context
export const ThemeImagesDataProvider = ({ children }) => {
  const {
    data: themeImagesData,
    isLoading: isLoadingThemeImagesData,
    isError: isErrorThemeImagesData,
    error: errorThemeImagesData,
  } = useGetThemeImagesData();

  return (
    <ThemeImagesDataContext.Provider
      value={{
        data: themeImagesData,
        isLoading: isLoadingThemeImagesData,
        isError: isErrorThemeImagesData,
        error: errorThemeImagesData,
      }}
    >
      {children}
    </ThemeImagesDataContext.Provider>
  );
};
