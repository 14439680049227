import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import Select, { components } from "react-select";
import { FaChevronDown } from "react-icons/fa";
import { selectStyles } from "./selectStyles";
import { customOption } from "./customOptions";
import { useCategoriesData } from "../../../../Contexts/CategoriesDataContext";
import { useSubcategoriesData } from "../../../../Contexts/SubcategoriesDataContext";

const SelectWrap = styled.div`
  width: 350px;
  margin-bottom: 30px;
  @media screen and (max-width: 600px) {
    width: 270px;
  }
`;

// Custom Dropdown Indicator Component
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaChevronDown style={{ color: "black", marginRight: "8px" }} />
    </components.DropdownIndicator>
  );
};

const MySelectList = ({
  category,
  menuItem,
  setFilteredProducts,
  products,
  currentTheme,
}) => {
  console.log("category", category);
  console.log("menuItem", menuItem);
  const { data: categoriesData } = useCategoriesData();
  const { data: subcategoriesData } = useSubcategoriesData();
  const [
    categoriesForMenuItemWithNoDropdown,
    setCategoriesForMenuItemWithNoDropdown,
  ] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);

  useEffect(() => {
    if (!category) {
      const categoriesForMenuItemWithNoDropdown = categoriesData.filter(
        (cat) => cat.menuItemId === menuItem.id
      );
      setCategoriesForMenuItemWithNoDropdown(
        categoriesForMenuItemWithNoDropdown
      );
      const options = categoriesForMenuItemWithNoDropdown.map((cat) => {
        return {
          value: cat.id,
          label: cat.name,
          icon: (
            <img
              src={
                currentTheme
                  ? currentTheme.productListPageSelectListIconSrc
                  : ""
              }
              alt=" icon"
              style={{ width: "20px", height: "20px" }}
            />
          ),
        };
      });
      setSelectOptions(options);
    } else {
      if (subcategoriesData) {
        const subcategoriesForCategoryForMenuItemWithDropdown =
          subcategoriesData.filter(
            (subcat) => subcat.categoryId === category.id
          );
        const options = subcategoriesForCategoryForMenuItemWithDropdown.map(
          (subcat) => {
            return {
              value: subcat.id,
              label: subcat.name,
              icon: (
                <img
                  src={
                    currentTheme
                      ? currentTheme.productListPageSelectListIconSrc
                      : ""
                  }
                  alt=" icon"
                  style={{ width: "20px", height: "20px" }}
                />
              ),
            };
          }
        );
        setSelectOptions(options);
      }
    }
  }, [categoriesData, menuItem, category, subcategoriesData]);

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      console.log("selectedOption", selectedOption);
      if (!category) {
        const filteredProducts = products.filter((product) =>
          product.categoriesIds.includes(selectedOption.value)
        );
        console.log("filteredProducts", filteredProducts);
        setFilteredProducts(filteredProducts);
      } else {
        const filteredProducts = products.filter((product) =>
          product.subcategoriesIds.includes(selectedOption.value)
        );
        console.log("filteredProducts", filteredProducts);
        setFilteredProducts(filteredProducts);
      }
    }
  };

  return (
    <>
      {selectOptions && selectOptions.length > 0 && (
        <SelectWrap>
          <Select
            options={selectOptions}
            getOptionLabel={(e) => e.label}
            getOptionValue={(e) => e.value}
            styles={selectStyles}
            placeholder="Selectează"
            components={{
              DropdownIndicator: DropdownIndicator,
              Option: customOption,
            }}
            onChange={handleSelectChange}
          />
        </SelectWrap>
      )}
    </>
  );
};

export default MySelectList;
