export   const customOption = (props,currentTheme) => {
    const { data, innerRef, innerProps, isSelected, isFocused } = props;

    return (
      <div
        ref={innerRef}
        {...innerProps}
        style={{
          display: "flex",
          alignItems: "center",
          color: "black",
          fontWeight: "400",
          padding: "10px 15px",
          cursor: "pointer",
          ...(isFocused && {
            backgroundColor: currentTheme
              ? currentTheme.productListPageSelectListCustomOptionFocusBackgroundColor
                ? currentTheme.productListPageSelectListCustomOptionFocusBackgroundColor
                : "lightgrey"
              : "lightgrey",
          }),
        }}
      >
        <span style={{ marginRight: "10px" }}>{data.icon}</span>
        <span>{data.label}</span>
      </div>
    );
  };