import React from "react";
import { Link } from "react-router-dom";
import { useThemesData } from "../../../Contexts/ThemesDataContext";
import styled from "@emotion/styled";
import SingleTheme from "../../Components/ThemeComponents/SingleTheme";

const ListWrap = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ListOfThemes = () => {
  const { data: themesData } = useThemesData();

  return (
    <div className="d-flex flex-column align-items-center justify-content-center w-100">
      <h5>Lista teme</h5>
      <Link to="/admin/adauga-tema">Adauga tema</Link>
      <ListWrap>
        {themesData.map((theme, index) => (
          <SingleTheme key={theme.id} theme={theme} index={index} />
        ))}
      </ListWrap>
    </div>
  );
};

export default ListOfThemes;
