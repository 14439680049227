import React from "react";
import styled from "@emotion/styled";

const ProductDescriptionWrap = styled.div``;
const ProductDescriptionTitle = styled.div``;
const ProductDescriptions = styled.ul``;
const Description = styled.li``;

const DescriptionsDiv = ({ product, currentTheme }) => {
  return (
    <div style={{ marginTop: "20px" }}>
      {product.productDescriptions &&
        product.productDescriptions.length > 0 && (
          <ProductDescriptionWrap>
            <ProductDescriptionTitle
              style={{
                color: currentTheme
                  ? currentTheme.productPageValabilityColor
                  : "rgb(210, 4, 45)",
                fontWeight: "600",
              }}
            >
              Descrierea produsului:
            </ProductDescriptionTitle>
            <ProductDescriptions>
              {product.productDescriptions.map((description) => {
                return (
                  <Description key={description.id}>
                    {description.description}
                  </Description>
                );
              })}
            </ProductDescriptions>
          </ProductDescriptionWrap>
        )}
    </div>
  );
};

export default DescriptionsDiv;
