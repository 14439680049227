import { useQuery } from "@tanstack/react-query";
import { Axios } from "../../config";

const useGetMenuItemsData = () => {
  const getListOfMenuItems = async () => {
    const { data } = await Axios.get("menuitems");
    return data;
  };

  return useQuery({
    queryKey: ["getListOfMenuItems"],
    queryFn: getListOfMenuItems,
  });
};

export default useGetMenuItemsData;
