import React from "react";
import styled from "@emotion/styled";
import Select from "react-select";
import { useState, useEffect } from "react";
import { useIngredientsData } from "../../../Contexts/IngredientsDataContext";
import { ErrorMessage } from "formik";

const Label = styled.div`
  color: red;
`;

const SelectIngredientsDiv = ({ name, setFieldValue, value }) => {
  const [ingredientsList, setIngredientsList] = useState([]);
  const { data: ingredientsData } = useIngredientsData();

  useEffect(() => {
    if (ingredientsData) {
      const transformedData = ingredientsData.map((ingredient) => ({
        value: ingredient.id,
        label: ingredient.name,
      }));
      setIngredientsList(transformedData);
    }
  }, [ingredientsData]);
  return (
    <div>
      <Label htmlFor={name}>Ingrediente descrise</Label>
      <Select
        isMulti
        isSearchable={true}
        name={name}
        value={value}
        options={ingredientsList}
        className="basic-multi-select mb-2"
        classNamePrefix="select"
        onChange={(selectedOptions) => {
          const values = selectedOptions.map((option) => option.value);
          console.log("values:", values);
          setFieldValue("selectedIngredientsIds", values);
          setFieldValue("selectedIngredientsOptions", selectedOptions);
        }}
        styles={{
          control: (provided) => ({
            ...provided,
            height: "150px",
            minHeight: "60px",
            fontSize: "16px",
          }),
        }}
      />
      <ErrorMessage
        name="selectedIngredientsIds"
        component="div"
        className="alert alert-danger"
      />
    </div>
  );
};

export default SelectIngredientsDiv;
