import { useQuery } from "@tanstack/react-query";
import { Axios } from "../../config";

const useGetProductsWithNameImage = () => {
  const getProductsWithNameImage = async () => {
    try {
      const { data } = await Axios.get("products/getProductsWithNameImage");
      //console.log("API Response:", data); // Add this to debug
      return data;
    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  };
  return useQuery({
    queryKey: ["getProductsWithNameImage"],
    queryFn: getProductsWithNameImage,
  });
};

export default useGetProductsWithNameImage;
