import React from "react";
import styled from "@emotion/styled";
import * as AiIcons from "react-icons/ai";

const CloseButton = styled.button`
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 10px;
  font-size: 20px;
  border-radius: 10px;
`;

const CloseBtn = ({ currentTheme, setSideNav, sideNav }) => {
  return (
    <CloseButton
      onClick={() => setSideNav(!sideNav)}
      style={{
        color: currentTheme ? `${currentTheme.closeIconXColor}` : "black",
        backgroundColor: currentTheme
          ? `${currentTheme.backgroundCloseIcon}`
          : " rgba(243, 218, 214, 1)",
      }}
    >
      <AiIcons.AiOutlineClose />
    </CloseButton>
  );
};

export default CloseBtn;
