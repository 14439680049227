import React, { useState } from "react";

const normalizeString = (str) =>
  str
    .normalize("NFD") // Normalizează caracterele pentru a separa diacriticele.
    .replace(/[\u0300-\u036f]/g, "") // Elimină caracterele diacritice.
    .toLowerCase(); // Convertim totul la litere mici pentru comparație.

const SearchBar = ({ onResults, list, style }) => {
  const [query, setQuery] = useState("");

  const handleSearch = (query) => {
    setQuery(query);
    const normalizedQueryWords = normalizeString(query).split(" "); // Normalizează și separă query-ul în cuvinte.
    const filtered = list.filter((item) => {
      const normalizedTitle = normalizeString(item.name);
      // Verificăm dacă toate cuvintele din query sunt prezente în titlu.
      return normalizedQueryWords.every((word) =>
        normalizedTitle.includes(word)
      );
    });
    onResults(filtered); // Pass the filtered results back to the parent
  };
  return (
    <input
      type="text"
      placeholder="Search..."
      value={query}
      onChange={(e) => handleSearch(e.target.value)}
      className="form-control mb-3"
      style={style}
    />
  );
};

export default SearchBar;
