import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Axios } from "../../../config";

export const useAddSubcategory = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async (newSubcategory) =>
      await Axios.post("subcategories", newSubcategory).then((res) => {
        console.log(res.data);
      }),
    onMutate: (newSubcategory) => {
      const previousSubcategories = queryClient.getQueryData([
        "getListOfSubcategories",
      ]);
      queryClient.setQueryData(["getListOfSubcategories"], (oldData) => [
        ...(oldData || []),
        { id: Date.now(), ...newSubcategory },
      ]);
      return { previousSubcategories };
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["getListOfSubcategories"]);
      navigate("/admin/lista-subcategorii");
    },
    onError: (error, context) => {
      queryClient.setQueryData(
        ["getListOfSubcategories"],
        context.previousCategories
      );
      console.error("Eroare la adăugarea subcategoriei:", error);
    },
  });
};
