import * as Yup from "yup";
export const editMenuItemInitialValues = (
  currentMenuItem,
  setInitialValues
) => {
  setInitialValues({
    name: currentMenuItem?.name,
    hasdropdown: currentMenuItem?.hasdropdown,
    path: currentMenuItem?.path,
  });
};
