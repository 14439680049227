import { useQueryClient } from "@tanstack/react-query";
import { Axios } from "../../../config";

export const useDeleteTheme = () => {
  const queryClient = useQueryClient();
  const deleteTheme = async (id) => {
    await queryClient.cancelQueries(["getListOfThemes"]);

    const previousThemes = queryClient.getQueryData(["getListOfThemes"]);

    queryClient.setQueryData(["getListOfThemes"], (oldData) =>
      oldData.filter((theme) => theme.id !== id)
    );

    try {
      await Axios.delete(`themes/${id}`);
    } catch (error) {
      queryClient.setQueryData(["getListOfThemes"], previousThemes);
      console.error("Eroare la ștergerea temei:", error);
    }
  };
  return deleteTheme;
};
