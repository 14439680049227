import React from "react";

const SubmitButton = ({ btnText }) => {
  return (
    <button
      type="submit"
      className="btn btn-primary mt-2 "
      style={{ height: "50px", width: "50%" }}
    >
      <div style={{ fontSize: "25px" }}> {btnText}</div>
    </button>
  );
};

export default SubmitButton;
