import React from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";

const Lucille = styled.div`
  font-size: 60px;
  font-family: "Lavishly Yours", cursive;
  font-weight: 700;

  @media screen and (max-width: 600px) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 30px;
  }
`;

const Lucilles = ({ currentTheme }) => {
  return (
    <Link to="/" style={{ textDecoration: "none" }}>
      <Lucille
        style={{
          backgroundImage: currentTheme
            ? `linear-gradient(${currentTheme.startLinearGradientLucilles}, 
            ${currentTheme.endLinearGradientLucilles})`
            : `linear-gradient(to right, #8f1e35, #e9b3b3)`,
          backgroundClip: "text",
          WebkitBackgroundClip: "text", // for cross-browser support
          color: "transparent", // makes the gradient visible on the text
          WebkitTextFillColor: "transparent", // also for cross-browser support
        }}
      >
        Lucille's
      </Lucille>
    </Link>
  );
};

export default Lucilles;
