import React from "react";
import styled from "@emotion/styled";

const Header = styled.div`
  width: 100%;
  height: 600px;
  position: relative;

  @media screen and (max-width: 600px) {
    height: 400px;
  }
`;
const LucilleNaturalCareImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;

  @media screen and (max-width: 600px) {
    width: 250px;
  }
`;
const HeaderDiv = ({ currentTheme }) => {
  return (
    <Header>
      <img
        src={currentTheme ? currentTheme.homePageImgSrc : null}
        alt="home-page-img  "
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          objectPosition: "20% 70%",
        }}
      />
      <LucilleNaturalCareImg
        src={
          currentTheme ? currentTheme.homePageLucilleNaturalCareImgSrc : null
        }
        alt="Lucille img"
      />
    </Header>
  );
};

export default HeaderDiv;
